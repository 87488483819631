#vertical-sicbo {
    $Grid: 25;
    $MainWidth: calc(100vw - 8px);

    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    max-width: 100%;
    height: 100%;
    // background-image: url("../../../assets/lobby/asd.jpg");
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;

    .header-container {
        padding: 5px 4px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: calc(100% / $Grid * 0.8);
        width: 100%;
        color: gold;
        font-size: 1em;
        font-weight: 600;
        font-style: italic;
        text-decoration: underline;

        .header-title {
            height: 100%;
            white-space: nowrap;
            display: flex;
            justify-content: start;
            align-items: center;
            font-size: 1em;
            font-weight: 600;
            text-decoration: underline;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 12px;
            padding: 2px;
            gap: 10px;

            .header-btn {
                height: 100%;
                aspect-ratio: 1/1;
                cursor: pointer;
            }
        }

        .header-menu {
            height: 100%;
        }
    }

    .status-container {
        z-index: 1;
        width: 100%;
        height: 8%;
        position: absolute;
        top: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(
            to right,
            rgb(9, 23, 40, 0),
            rgb(9, 23, 40, 0.6),
            rgb(9, 23, 40, 0.8),
            rgb(9, 23, 40, 0.8),
            rgb(9, 23, 40, 0.6),
            rgb(9, 23, 40, 0)
        );

        .status-wrapper {
            width: 80%;
            white-space: wrap;
            display: flex;
            justify-content: center;
            align-items: center;
            background: -webkit-linear-gradient(#c9ad49, #f8ec65, #c9ad49);
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .live-streaming-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: calc(100% / $Grid * 6);

        .road-map-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 10%;
        }

        .id-wrapper {
            position: absolute;
            bottom: 12%;
            left: 2%;
            background-color: rgb(0, 0, 0, 0.5);
            padding: 5px;
            border-radius: 10px;

            .uid {
                color: #ddd;
                font-size: 0.8em;
                font-weight: 700;
            }
        }

        .timer-container {
            position: absolute;
            top: 12%;
            right: 2%;
            height: 30%;
            aspect-ratio: 1/1;
            z-index: 2;
        }
    }

    .action-msg-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 8%;
        z-index: 2;

        :is(&.undefined) {
            visibility: hidden;
        }

        :is(&.startBet, &.stopBet, &.nextRound, &.lastGame, &.cancelRound) {
            animation: fade-anim 3s ease-in-out forwards;

            @keyframes fade-anim {
                50% {
                    opacity: 1;
                    display: block;
                }
                100% {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }

    .bet-panel-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: calc(100% / $Grid * 15.7);
        overflow: hidden;
    }

    .chips-set-container {
        padding: 0px 4px;
        height: calc(100% / $Grid * 2);
        max-height: calc(100% / $Grid * 2);
    }

    .win-list-wrapper {
        position: absolute;
        right: 1%;
        top: 10%;
        height: 80%;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        z-index: 3;
    }

    .footer-container {
        padding: 0px 4px 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: calc(100% / $Grid * 0.5);

        .container-01 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            height: 100%;
            width: 70%;
            color: #ddd;

            .lbl {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 1.25%;
                border: 2px solid #ddd;
                border-radius: 25px;
                font-size: 0.7em;
                font-weight: 600;
                width: 55%;
                max-width: 250px;
                white-space: nowrap;
            }
        }

        .container-02 {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 30%;
            color: gold;
            font-size: 0.7em;
            font-weight: 600;
        }
    }
}
