.vbet-panel-dream-catcher {
  width: 100%;
  height: 100%;

  .blue-red-number-container {
    width: 100%;
    height: 42.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bet-items {
      position: relative;
      padding: 10px;
      cursor: pointer;
      width: calc(100% / 6 * 0.98);
      height: 98%;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: 0px 0px 0px 1px black inset;
      gap: 20px;
      font-weight: 600;

      .animal {
        width: 100%;
        max-width: 50px;
        max-height: 60px;
        height: 50%;
      }

      .chip-placed {
        position: absolute;
        width: 50%;
        height: 90%;
      }
    }
  }
  .blue-red-odd-even-container {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .bet-items {
      cursor: pointer;
      width: calc(100% / 4 * 0.99);
      height: 100%;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: 0px 0px 0px 1px black inset;
      gap: 20px;
      font-weight: 600;
      .animal {
        width: 50%;
        height: 50%;
      }

      .chip-placed {
        position: absolute;
        height: 90%;
        max-height: 40px;
      }
    }
  }
}
