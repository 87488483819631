.baccarat-bet-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
    height: 100%;
    width: 100%;

    .chip-placed {
        position: absolute;
    }

    .main-bet-item {
        // aspect-ratio: 2 / 1;
        // height: calc(100% / 5 * 3);
        width: 100%;
        display: flex;
        gap: 5px;

        .player-tie-banker {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;

            .chip-placed {
                height: 30%;
                aspect-ratio: 1/1;
            }

            .btn-main-bet {
                position: absolute;
                top: 0;
                height: 100%;
                width: calc(100% / 2);
                overflow: hidden;
                font-weight: 600;
                font-size: 0.7rem;
                cursor: pointer;

                .point-container {
                    position: absolute;
                    top: 10%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    color: #ddd;
                    font-size: 1rem;

                    &.PW {
                        right: 30%;
                    }
                    &.BW {
                        left: 30%;
                    }
                }

                .result-card-container {
                    position: absolute;
                    top: 35%;
                    height: 35%;
                    width: 55%;
                    max-width: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;

                    .result-card-wrapper {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 5%;

                        .result-card {
                            height: 100%;
                            width: calc(100% / 3);
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &.rotate-right {
                                transform: rotate(90deg) translateY(15%);
                            }

                            &.rotate-left {
                                transform: rotate(-90deg) translateY(15%);
                            }
                        }

                        .card-cont {
                            background-color: transparent;
                            width: 100%;
                            height: 100%;
                            perspective: 1000px;
                        }

                        .inner-card {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            transition: transform 0.6s;
                            transform-style: preserve-3d;
                        }

                        .flip {
                            transform: rotateY(180deg);
                        }

                        .card-front,
                        .card-back {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            -webkit-backface-visibility: hidden;
                            backface-visibility: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .card-front {
                            color: black;
                        }

                        .card-back {
                            color: white;
                            transform: rotateY(180deg);
                        }

                        .rotate-right {
                            transform: rotate(90deg) translateY(8px);
                        }

                        .rotate-left {
                            transform: rotate(-90deg) translateY(8px);
                        }
                    }

                    &.PW {
                        left: 10%;
                    }

                    &.BW {
                        right: 10%;
                    }
                }

                @media only screen and (max-width: 576px) {
                    .result-card-container {
                        height: 30%;
                    }
                }

                .value-rate {
                    position: absolute;
                    color: #ddd;
                    bottom: 5%;
                    font-size: 1.5em;
                }

                img {
                    height: 100%;
                    width: 100%;
                }

                .tie-progress {
                    flex-direction: column;
                }

                .banker-progress {
                    flex-flow: row-reverse;
                }

                .progress {
                    position: absolute;
                    top: 5%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    .ant-progress-text {
                        color: #ddd;
                    }

                    .PW-text {
                        color: #4e95ed;
                        font-size: 0.7rem;
                    }
                    .BW-text {
                        color: #f54638;
                        font-size: 0.7rem;
                    }
                    .T-text {
                        color: #5ec93d;
                        font-size: 0.7rem;
                    }
                }

                &.PW {
                    bottom: 0.1%;
                    left: 0;

                    .chip-placed {
                        bottom: 5%;
                        right: 30%;
                    }

                    .value-rate,
                    .progress {
                        left: 5%;
                    }
                }

                &.BW {
                    right: 0;

                    .chip-placed {
                        bottom: 5%;
                        left: 30%;
                    }

                    .value-rate,
                    .progress {
                        right: 5%;
                    }
                }

                &.T {
                    right: 36.8%;
                    top: 13%;
                    width: calc(100% / 3.8);
                    height: 87%;

                    .chip-placed {
                        height: 35%;
                        aspect-ratio: 1/1;
                        bottom: 5%;
                        right: 50%;
                        transform: translate(50%, 0%);
                    }

                    .value-rate,
                    .progress {
                        width: 100%;
                        text-align: center;
                    }

                    .progress {
                        top: 15%;
                    }
                }

                &.win {
                    animation: light 1.5s infinite ease-in-out;
                    @keyframes light {
                        0% {
                            filter: brightness(1);
                        }
                        50% {
                            filter: brightness(3);
                        }
                        100% {
                            filter: brightness(1);
                        }
                    }
                }
            }
        }
    }

    .sub-bet-item {
        overflow: hidden;
        display: inline-flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-around;
        transition: all 1s linear;

        .btn-bet-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 8px;
            background: rgb(38, 38, 38, 0.7);
            box-shadow: 0px 0px 0px 1px #8c8c8c inset;
            border-radius: 6px;
            color: #ddd;
            font-weight: 600;
            font-size: 0.8rem;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            .chip-placed {
                height: 80%;
                aspect-ratio: 1/1;
                bottom: 10%;
                right: 40%;
            }

            span:first-child {
                width: 85%;
                padding-right: 1%;
            }

            :is(&.PP, &.BP, &.B6, &.B6T) {
                width: calc(100% / 2.02);
            }

            :is(&.B6, &.B6T) {
                font-size: 0.8em;
                font-weight: 600;
                // width: calc(100% / 2.02);
                background-color: rgba(57, 16, 133, 0.7);
                box-shadow: 0px 0px 0px 1px #8c8c8c inset;
            }

            :is(&.SPT, &.TT) {
                font-size: 0.7em;
                font-weight: 600;
                width: calc(100% / 4.04);
                background-color: rgb(57, 16, 133, 0.7);
                box-shadow: 0px 0px 0px 1px #8c8c8c inset;
            }

            :is(&.SMT) {
                font-size: 0.7em;
                font-weight: 600;
                width: calc(100% / 4.04);
                background-color: rgb(187, 96, 22, 0.7);
                box-shadow: 0px 0px 0px 1px #8c8c8c inset;
            }

            :is(&.BT) {
                font-size: 0.7em;
                font-weight: 600;
                width: calc(100% / 4.04);
                background-color: rgb(163, 23, 23, 0.7);
                box-shadow: 0px 0px 0px 1px #8c8c8c inset;
            }

            &.win {
                animation: light 1.5s infinite ease-in-out;
                @keyframes light {
                    0% {
                        filter: brightness(1);
                    }
                    50% {
                        filter: brightness(1.5);
                    }
                    100% {
                        filter: brightness(1);
                    }
                }
            }
        }
    }
}
