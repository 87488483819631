.road-map {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    flex-wrap: wrap;

    .road-map-item-wrapper {
        height: 100%;
        width: calc(100% / 15);
        min-width: calc(100% / 15);
    }
}
