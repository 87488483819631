#horizontal-baccarat {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 18 / 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-spin-blur,
    .live-streaming-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .horizontal-baccarat-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        .action-msg-container {
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translate(-50%, 0);
            width: 45%;
            height: 15%;
            max-width: 500px;
            max-height: 80px;
            z-index: 2;
            opacity: 0.8;

            :is(&.undefined) {
                visibility: hidden;
            }

            :is(&.startBet, &.stopBet, &.nextRound, &.lastGame, &.cancelRound) {
                animation: fade-anim 3s ease-in-out forwards;

                @keyframes fade-anim {
                    50% {
                        opacity: 1;
                        display: block;
                    }
                    100% {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }

        .timer-container {
            position: absolute;
            //   top: 12%;
            top: 10%;
            //   right: 2%;
            right: 1%;
            height: 15%;
            max-height: 80px;
            aspect-ratio: 1/1;
            z-index: 2;
        }

        .header-container {
            position: absolute;
            top: 1%;
            padding: 0 1%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 8%;
            max-height: 45px;
            width: 100%;
            color: gold;

            .header-title {
                height: 100%;
                white-space: nowrap;
                display: flex;
                justify-content: start;
                align-items: center;
                font-size: 1em;
                font-weight: 600;
                text-decoration: underline;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 12px;
                padding: 5px;
                gap: 10px;

                .header-btn {
                    height: 100%;
                    aspect-ratio: 1/1;
                    cursor: pointer;
                }
            }

            .header-menu {
                height: 100%;
            }
        }

        .tbl-btn-container {
            position: absolute;
            bottom: 12%;
            right: 1%;
            height: 10%;
            max-height: 50px;
            aspect-ratio: 1/1;
            z-index: 1;
            cursor: pointer;

            .tbl-btn-wrapper {
                width: 100%;
                height: 100%;
            }
        }

        .win-list-wrapper {
            position: absolute;
            right: 1%;
            top: 10%;
            // width: 25%;
            // max-width: 350px;
            height: 60%;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            z-index: 3;
        }

        .footer-container {
            position: absolute;
            bottom: 1%;
            width: fit-content;

            .footer-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5px;
                height: 100%;
                width: 100%;
                color: #ddd;

                .lbl {
                    display: flex;
                    align-items: center;
                    padding: 1.25%;
                    font-size: 0.85em;
                    font-weight: 600;
                    width: 100%;
                    white-space: nowrap;
                }

                .lbl-max-bet {
                    color: gold;
                }

                .id-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    .lbl-id {
                        display: flex;
                        align-items: center;
                        padding: 1.25%;
                        width: 100%;
                        white-space: nowrap;

                        &.reconnecting {
                            color: orange;
                        }
                        &.reconnected {
                            color: green;
                        }
                        &.offline {
                            color: red;
                        }
                    }
                }
            }
        }

        .total-bet {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px 10px;
            left: 1%;
            .lbl {
                justify-content: flex-start;
            }
        }

        .max-bet {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px 10px;
            right: 1%;
            .lbl {
                justify-content: flex-end;
            }
        }

        .bet-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0.95);
            height: 98%;
            max-height: 500px;
            width: 50cqw;
            max-width: 700px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 2px;
            will-change: auto;
            transition: all 0.5s linear;

            .bet-panel-btn-wrapper {
                background-color: rgba(0, 0, 0, 0.5);
                padding: 2px;
                border-radius: 4px;
                width: 100%;
                height: 15%;
                will-change: auto;
                transition: all 1s linear;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                transition: all 1s 0.5s linear;

                .chips-set-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                }
            }

            .bet-panel-container {
                width: 100%;
                height: 60%;
                will-change: auto;
                transition: all 1s linear;
            }

            .road-maps-statistic-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 2%;
                height: 5%;
                width: 100%;
                background-color: rgb(38, 38, 38, 0.7);
                border-radius: 4px;
                transition: all 1s 0.5s linear;

                .statistic-forcast {
                    display: flex;
                    justify-content: space-between;
                    height: 100%;
                    width: 100%;
                    padding: 0.5% 0;

                    .statistic-count {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;
                        height: 100%;
                        width: 50%;

                        .lbl {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 5px;
                            height: 100%;
                            color: #ddd;
                            font-size: 0.7em;
                            font-weight: 700;

                            .lbl-dot {
                                height: 65%;
                            }
                        }
                    }

                    .forcast-wrapper {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 8px;
                        width: 50%;
                        height: 100%;

                        :is(.player-forcast, .banker-forcast) {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            padding: 2.5px 10px;
                            background-color: rgba(0, 0, 0, 0.2);
                            border-radius: 18px;
                            border: 1px solid #ddd;
                            height: 100%;

                            .lbl-text {
                                color: #fff;
                                font-size: 0.65em;
                                font-weight: 600;
                            }

                            .lbl-dot {
                                height: 50%;
                            }
                        }
                    }
                }
            }

            .road-maps-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 3px;
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                height: 100%;
                max-height: 25%;
                transition: all 1s 0.5s linear;

                .road-1 {
                    aspect-ratio: 1 / 1;
                    height: 100%;
                }

                .roads-croc-small {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    width: 100%;
                    max-width: 100%;
                    border-radius: 4px;
                    overflow-y: hidden;
                    overflow-x: auto;

                    .roads-croc {
                        width: fit-content;
                        min-width: 100%;
                        height: 70%;
                    }

                    .roads-small {
                        position: sticky;
                        left: 0;
                        bottom: 0;
                        display: flex;
                        height: 30%;
                        width: 100%;
                        max-width: 100%;

                        .roads-small-item {
                            height: 100%;
                            width: calc(100% / 3);
                        }
                    }
                }
            }

            &.stop-bet {
                top: 100%;
                transition: all 1s 0.5s linear;
                transform: translate(-50%, -30%) scale(0.75);
                // transform: translate(-50%, -25%) scale(0.65) perspective(500px) rotateX(40deg);
                // transform-origin: 50% 65%;
                transform-origin: top;
                opacity: 0.75;

                .sub-bet-item {
                    opacity: 0;
                    transition: all 0.5s linear;
                }

                .bet-panel-btn-wrapper {
                    height: 0 !important;
                    overflow: hidden;
                    opacity: 0;
                    transition: all 0.5s linear;
                }

                .road-maps-statistic-container {
                    height: 0 !important;
                    overflow: hidden;
                    opacity: 0;
                    transition: all 0.5s linear;
                }
                .road-maps-container {
                    height: 0 !important;
                    overflow: hidden;
                    opacity: 0;
                    transition: all 0.5s linear;
                }
            }
        }
    }
}
