.v-chips {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    .btn-function {
        height: calc(100% / 3);
        aspect-ratio: 1/1;
        transform: scale(0.85);
        transition: all 0.5s ease;

        :is(&:hover, &:active) {
            transform: scale(0.95);
            z-index: 2 !important;
        }
    }

    .chips-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% / 3);
        aspect-ratio: 1/1;

        .btn-chip-move {
            position: absolute;
            transition: all 0.5s ease;

            img {
                width: 41.26px;
                aspect-ratio: 1/1;
            }
        }

        .btn-chip {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            aspect-ratio: 1/1;
            // height: 100%;
            transform: scale(0.9);
            transition: all 0.5s ease;

            :is(&:hover, &:active, &.true) {
                transform: scale(1);
            }
        }

        .spread-0 {
            translate: 0% -260%;
        }
        .spread-1 {
            translate: 100% -220%;
        }
        .spread-2 {
            translate: 170% -145%;
        }
        .spread-3 {
            translate: 200% -50%;
        }
        .spread-4 {
            translate: 200% 50%;
        }
        .spread-5 {
            translate: 170% 145%;
        }
        .spread-6 {
            translate: 100% 220%;
        }
        .spread-7 {
            translate: 0px 260%;
        }

        .spread-99 {
            translate: 100% 0%;
        }
    }

    .selected-chips-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 25%;
        aspect-ratio: 1/1;
        z-index: 1;

        .btn-chip {
            width: fit-content;
            height: 100%;
        }
    }
}

@media only screen and (orientation: landscape) {
    @media only screen and (min-width: 800px) {
        .v-chips {
            .chips-container {
                .spread-99 {
                    translate: 110% 0%;
                }
            }
        }
    }
}
