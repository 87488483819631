.suggestion-modal {
    .ant-modal-content {
        background-color: rgb(0, 0, 0, 0.7);
        // padding: 12px;

        .ant-modal-close {
            span {
                img {
                    max-width: 100%;
                }
            }
        }
    }

    .feedback-form {
        .ant-form-item {
            .ant-form-item-label {
                .ant-form-item-required {
                    color: white;
                }
            }
            .ant-select {
                .ant-select-selector {
                    background-color: rgb(0, 0, 0, 0.5);
                    border-color: gold;
                    color: gold;

                    .ant-select-selection-placeholder {
                        color: gold;
                    }

                    &:hover {
                        border-color: gold;
                    }
                }
            }

            .ant-input {
                background-color: rgb(0, 0, 0, 0.5);
                border-color: gold;
                color: gold;

                &::placeholder {
                    color: gold;
                }
            }

            .ant-btn {
                background: black;
                border: 1px solid gold;
                color: gold;
            }
        }
    }
}
