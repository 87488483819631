::-webkit-scrollbar {
    display: none;
}
.nv-chips {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 100%;
    gap: 2px;

    .wrapper-1 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
        width: 100%;

        .btn-function {
            width: calc(100% / 8);
            height: 100%;
        }

        .btn-function-1 {
            width: calc(100% / 8 * 2);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &.shine {
                animation: shine 1s forwards ease-in-out infinite;

                @keyframes shine {
                    0% {
                        filter: brightness(1);
                    }
                    50% {
                        filter: brightness(2);
                    }
                    100% {
                        filter: brightness(1);
                    }
                }
            }
        }
    }

    .wrapper-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
        width: 100%;

        .chips-container {
            height: 100%;
            width: 100%;

            .swiper-wrapper {
                .swiper-slide {
                    margin-right: 0 !important;
                }
            }

            .btn-chip {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
