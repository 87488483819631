.collapse-title {
    .ant-collapse {
        background-color: rgba(0, 0, 0, 0.5);

        .ant-collapse-item {
            .ant-collapse-header {
                .ant-collapse-header-text {
                    color: #ddd;
                }
            }
            .ant-collapse-content {
                background-color: rgb(51, 51, 51, 0.5);

                .ant-collapse-content-box {
                    padding: 12px;

                    .ant-typography {
                        margin: 0;
                        color: #ddd;
                    }
                }
            }
        }
    }

    .ant-descriptions {
        .ant-descriptions-view {
            border-color: #bfbfbf;

            .ant-descriptions-row {
                border-color: #bfbfbf;
            }
        }
        .ant-descriptions-item-label {
            font-size: 10px;
            padding: 10px;
            color: #ddd;
            border-color: #bfbfbf;
        }
        .ant-descriptions-item-content {
            font-size: 10px;
            color: #ddd;
            padding: 10px;
        }
    }

    .ant-table {
        background: transparent;
        font-size: 9px;
    }
    .ant-table-thead > tr > th {
        background: transparent;
        color: #ddd;
        padding: 8px;
    }

    .ant-table-tbody > tr > td {
        background: transparent;
        color: #ddd;
        padding: 2px;
    }
}
