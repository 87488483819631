.try-modal {
    .ant-modal-body {
        padding: 0px;
    }

    .ant-modal-content {
        background-color: transparent;
        padding: 0;

        .ant-modal-close-x {
            color: white;
        }
    }
}
