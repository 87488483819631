.timer {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 50%;

  div {
    width: 100% !important;
    height: 100% !important;
    svg {
      width: 100% !important;
      height: 100% !important;
    }
  }

  p {
    padding: 0;
    margin: 0;

    svg {
      width: 1.6rem !important;
      height: 1.6rem !important;
    }
  }

  .timer-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    font-family: "Montserrat";
  }

  .time-wrapper .time {
    position: absolute;
    left: 0;
    top: 0;
    font-weight: 600;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    opacity: 1;
    transition: all 0.2s;
  }

  .time-wrapper .time.up {
    opacity: 0;
    transform: translateY(-100%);
  }

  .time-wrapper .time.down {
    opacity: 0;
    transform: translateY(100%);
  }

  .timer-loading {
    font-weight: 600;
    animation: rotate 3s ease-in-out infinite;
  }

  @keyframes rotate {
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
