.sb-road-map-A {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: auto;

  .road-map-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: calc(100% / 7 * 0.95);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: #ddd;

    &:first-child {
      border: 1px solid #fff;
    }

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1cqw;
      height: 85%;
      width: calc(100% / 4 * 0.85);

      &.dice {
        background-color: #454240;
        border-radius: 3px;
      }
    }
  }
}

.sb-road-map-B {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 100%;
  height: 100%;
  overflow-x: auto;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 15);
    height: 100%;
    color: #fff;
    background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid #fff;
    border-bottom: 0px;
    border-radius: 4px 4px 0 0;
    font-size: 3cqw;
  }
}

.sb-road-map-C {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .row-item {
    $RowCount: 16;
    $ColCount: 4;

    width: 100% / $RowCount;
    height: 100%;

    .col-item {
      width: 100%;
      height: calc(100% / $ColCount);
    }
  }
}
