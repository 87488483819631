.setting-card {
  color: #ddd;

  .ant-card {
    background-color: #323232;
    border: 0;

    .ant-card-body {
      padding: 10px 20px;
      color: #ddd;
    }
  }

  .ant-space {
    width: 100%;
  }
  .ant-radio-wrapper {
    color: #ddd;

    .ant-radio-checked {
      .ant-radio-inner {
        border-color: goldenrod;
        background-color: goldenrod;
      }
    }
  }
}
