#vertical-baccarat {
  $Grid: 25;
  $MainWidth: calc(100vw - 8px);

  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  max-width: 100%;
  height: 100%;

  .header-container {
    padding: 5px 4px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(100% / $Grid * 1);
    width: 100%;
    color: gold;
    font-size: 1em;
    font-weight: 600;
    font-style: italic;
    text-decoration: underline;

    .header-title {
      height: 100%;
      white-space: nowrap;
      display: flex;
      justify-content: start;
      align-items: center;
      font-size: 1em;
      font-weight: 600;
      text-decoration: underline;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 12px;
      padding: 2px;
      gap: 10px;

      .header-btn {
        height: 100%;
        aspect-ratio: 1/1;
        cursor: pointer;
      }
    }

    .header-menu {
      height: 100%;
    }
  }

  .status-container {
    z-index: 1;
    width: 100%;
    height: 8%;
    position: absolute;
    top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      to right,
      rgb(9, 23, 40, 0),
      rgb(9, 23, 40, 0.6),
      rgb(9, 23, 40, 0.8),
      rgb(9, 23, 40, 0.8),
      rgb(9, 23, 40, 0.6),
      rgb(9, 23, 40, 0)
    );

    .status-wrapper {
      width: 80%;
      white-space: wrap;
      display: flex;
      justify-content: center;
      align-items: center;
      background: -webkit-linear-gradient(#c9ad49, #f8ec65, #c9ad49);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .live-streaming-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% / $Grid * 6);

    .id-wrapper {
      position: absolute;
      bottom: 5px;
      left: 10px;
      background-color: rgb(0, 0, 0, 0.5);
      padding: 5px;
      border-radius: 10px;

      .uid {
        color: #ddd;
        font-size: 0.8em;
        font-weight: 700;

        &.reconnecting {
          color: orange;
        }
        &.reconnected {
          color: green;
        }
        &.offline {
          color: red;
        }
      }
    }

    .bet-panel-btn-wrapper {
      position: absolute;
      bottom: 5px;
      right: 10px;

      .ant-switch {
        background-color: transparent;
        .ant-switch-handle {
          &::before {
            background-color: gold;
          }
        }

        .ant-switch-inner {
          background-color: rgb(0, 0, 0, 0.5);
          border: 1px solid gold;
        }
      }
    }

    .timer-container {
      position: absolute;
      top: 12%;
      right: 2%;
      height: 30%;
      aspect-ratio: 1/1;
      z-index: 2;
    }
  }

  .action-msg-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 8%;
    z-index: 2;

    :is(&.undefined) {
      visibility: hidden;
    }

    :is(&.startBet, &.stopBet, &.nextRound, &.lastGame, &.cancelRound) {
      animation: fade-anim 3s ease-in-out forwards;

      @keyframes fade-anim {
        50% {
          opacity: 1;
          display: block;
        }
        100% {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }

  .bet-panel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% / $Grid * 8.2);
  }

  .chips-set-container {
    padding: 0px 4px;
    height: calc(100% / $Grid * 2);
    max-height: calc(100% / $Grid * 2);
  }

  .tbl-btn-container {
    position: absolute;
    bottom: 5%;
    right: 3%;
    height: 5%;
    aspect-ratio: 1/1;
    z-index: 1;
    cursor: pointer;
    opacity: 0.4;
    transition: all 0.5s ease;

    :is(&:hover, &:active) {
      opacity: 1;
    }

    .tbl-btn-wrapper {
      width: 100%;
      height: 100%;
    }
  }

  .win-list-wrapper {
    position: absolute;
    right: 1%;
    top: 10%;
    // width: 30%;
    // max-width: 350px;
    height: 80%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    z-index: 3;
  }

  .road-map-statistic-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2%;
    height: calc(100% / $Grid * 1);
    background-color: rgb(38, 38, 38, 0.7);
    border-radius: 4px;

    .statistic-forcast {
      display: flex;
      justify-content: space-between;
      height: 100%;
      width: 100%;

      .statistic-count {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: clamp(50%, 100%, 50%);

        .lbl {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          width: 100%;
          color: #ddd;
          font-size: 0.7em;
          font-weight: 700;

          .lbl-dot {
            height: 65%;
          }
        }
      }

      .forcast-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        padding: 5px 0;
        width: 50%;
        height: 100%;

        :is(.player-forcast, .banker-forcast) {
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 5px 10px;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 18px;
          border: 1px solid #ddd;
          height: 100%;

          .lbl-text {
            color: #fff;
            font-size: 0.75em;
            font-weight: 600;
          }

          .lbl-dot {
            height: 65%;
          }
        }
      }
    }
  }

  .road-maps-container {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    min-width: 100%;
    height: calc(100% / $Grid * 5.5);
    overflow-x: auto;

    .road-1 {
      aspect-ratio: 1 / 1;
      height: 100%;
    }

    .roads-croc-small {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: fit-content;
      max-width: 90%;
      border-radius: 4px;

      .roads-croc {
        width: fit-content;
        height: 70%;
        min-width: 100%;
        max-width: 100%;
      }

      .roads-small {
        position: sticky;
        left: 0;
        bottom: 0;
        display: flex;
        height: 30%;
        width: calc(95vw - 8px);
        max-width: 100%;
        min-width: 100%;
        overflow: hidden;

        .roads-small-item {
          height: 100%;
          width: calc(100% / 3);
        }
      }
    }
  }

  .footer-container {
    padding: 0px 4px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(100% / $Grid * 1);

    .container-01 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      height: 100%;
      width: 70%;
      color: #ddd;

      .lbl {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1.25%;
        border: 2px solid #ddd;
        border-radius: 25px;
        font-size: 0.7em;
        font-weight: 600;
        width: 55%;
        max-width: 250px;
        white-space: nowrap;
      }
    }

    .container-02 {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 30%;
      color: gold;
      font-size: 0.7em;
      font-weight: 600;
    }
  }
}
