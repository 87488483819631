.cf-btn-view {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 5px;
    position: relative;

    .cf-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        aspect-ratio: 1/1;
        cursor: pointer;
    }

    .chat-box {
        position: absolute;
        top: 210%;
        left: 0%;
        height: 100%;
        width: 100%;
        min-height: 200px;
        max-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 2%;
        gap: 5px;

        .chat-box-wrapper {
            height: 85%;
            width: 100%;
            overflow: auto;
            padding: 3%;
            border-radius: 8px;
            background-color: rgb(0, 0, 0, 0.7);
            .chat {
                height: 20%;
                width: 95%;
                margin-bottom: 2px;
                color: white;
                display: flex;
                justify-content: start;
                align-items: center;
                font-size: 0.8em;
                font-weight: 600;

                .chat-msg {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                    }
                }

                .chat-table {
                    height: 100%;
                    width: 100%;
                    max-width: 100%;
                    max-height: 100%;
                    text-wrap: wrap;
                    overflow-x: auto;
                    overflow-y: hidden;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: start;
                    background-color: rgb(255, 255, 255, 0.3);
                    border-radius: 8px;
                    padding: 2%;
                    cursor: pointer;

                    span {
                        text-decoration: underline;
                        color: gold;
                    }
                }
            }
        }
    }

    .chat-box-input {
        position: absolute;
        top: 110%;
        right: 0%;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background-color: rgb(0, 0, 0, 0.7);
        border-radius: 8px;
        z-index: 2;

        .emoji {
            width: calc(100% / 6);
            height: 100%;
            cursor: pointer;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

@media only screen and (orientation: portrait) {
    .cf-btn-view {
        .chat-box {
            z-index: 100;
        }
    }
    @media only screen and (min-width: 1px) {
        .cf-btn-view {
            .chat-box {
                top: 210%;
                min-height: 150px;
                max-height: 200px;
            }
            .chat-box-input {
                max-width: 180px;
            }
        }
    }
    @media only screen and (min-width: 768px) {
        .cf-btn-view {
            .chat-box {
                min-height: 250px;
                max-height: 300px;
            }
            .chat-box-input {
                max-width: 250px;
            }
        }
    }
    @media only screen and (min-width: 1000px) {
        .cf-btn-view {
            .chat-box-input {
                max-width: 325px;
            }
        }
    }
}

@media only screen and (orientation: landscape) {
    @media only screen and (min-width: 1000px) {
        .cf-btn-view {
            .chat-box {
                top: 220%;
                min-height: 250px;
                max-height: 250px;

                .chat-box-wrapper {
                    height: 80%;
                }
                .chat-box-input {
                    height: 20%;
                }
            }
        }
    }
}
