.win-list {
    padding: 8px;
    // width: 100%;
    height: 100%;
    color: #ddd;
    background-color: transparent;
    overflow: hidden;

    .win-list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // width: 100%;
        height: fit-content;
        padding: 2px 0;
        margin-bottom: 5px;
        font-size: clamp(1em, 1em, 12px);
        font-weight: 600;
        white-space: nowrap;
        border-bottom: 1px solid #ddd;
        gap: 20px;

        .player-count {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
        }
    }

    .content-container {
        position: relative;
        // width: 100%;
        height: 100%;
        font-size: clamp(0.9em, 0.9em, 10px);
        overflow: scroll;
    }

    .content {
        position: absolute;
        width: 100%;
        height: fit-content;
        animation: movie-credit 3s linear;
        overflow-y: auto;

        .win-list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #ddd;
            white-space: nowrap;
            text-overflow: ellipsis;
            // gap: 5%;

            &:first-child {
                color: #ffd700;
            }

            &:nth-child(2) {
                color: #c0c0c0;
            }

            &:nth-child(3) {
                color: #cd7f32;
            }
        }

        @keyframes movie-credit {
            from {
                top: 100%;
            }
            to {
                top: 0%;
            }
        }
    }
}
