.tp-review-card {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100dvh;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;

    .close-icon {
        position: absolute;
        right: 1%;
        top: 5%;
        color: #ddd;
        font-size: 2em;
        z-index: 1;
    }

    .all-card-set {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .content-wrapper {
            width: 100%;
            height: fit-content;

            .card-set {
                cursor: pointer;

                &:not(:first-child) {
                    margin-top: 20px;
                }

                .title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ddd;
                    font-weight: 700;
                    font-size: 1em;
                }

                .content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    width: 100%;
                    aspect-ratio: 3 / 1;

                    .card-wrapper {
                        width: calc(100% / 4);
                        height: 100%;

                        &:first-child {
                            transform: translateX(50%);
                        }

                        &:last-child {
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
    }

    .scratch-card-set {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 95%;
        height: 100%;

        .card-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100% / 3.05);
            aspect-ratio: 1 / 1.5;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
    }
}
