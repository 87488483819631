.horizontal-four-seasons {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 18 / 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-spin-blur,
    .live-streaming-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .horizontal-four-seasons-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        .header-container {
            position: absolute;
            top: 1%;
            padding: 0 1%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 8%;
            max-height: 45px;
            width: 100%;
            color: gold;

            .header-title {
                height: 100%;
                white-space: nowrap;
                display: flex;
                justify-content: start;
                align-items: center;
                font-size: 1em;
                font-weight: 600;
                text-decoration: underline;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 12px;
                padding: 5px;
                gap: 10px;

                .header-btn {
                    height: 100%;
                    aspect-ratio: 1/1;
                    cursor: pointer;
                }
            }

            .header-menu {
                height: 100%;
            }
        }

        .timer-container {
            position: absolute;
            //   top: 12%;
            top: 10%;
            //   right: 2%;
            right: 1%;
            height: 15%;
            max-height: 80px;
            aspect-ratio: 1/1;
            z-index: 2;
        }

        .action-msg-container {
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translate(-50%, 0);
            width: 45%;
            height: 15%;
            max-width: 500px;
            max-height: 80px;
            z-index: 2;
            opacity: 0.5;

            &.undefined {
                display: none;
            }

            &.startBet,
            &.stopBet,
            &.nextRound,
            &.lastGame,
            &.cancelRound {
                animation: fade-anim 3s ease-in-out forwards;

                @keyframes fade-anim {
                    50% {
                        opacity: 1;
                        display: block;
                    }
                    100% {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }

        // .chips-set-container {
        //     position: absolute;
        //     top: 50%;
        //     left: 0%;
        //     transform: translate(50%, -50%);
        //     height: 80%;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     z-index: 1;

        //     .chips-set-wrapper {
        //         height: 40%;
        //         max-height: 250px;
        //     }
        // }

        .tbl-btn-container {
            position: absolute;
            bottom: 12%;
            right: 1%;
            height: 10%;
            max-height: 50px;
            aspect-ratio: 1/1;
            z-index: 1;
            cursor: pointer;

            .tbl-btn-wrapper {
                width: 100%;
                height: 100%;
            }
        }

        .win-list-wrapper {
            position: absolute;
            right: 1%;
            top: 10%;
            // width: 25%;
            // max-width: 350px;
            height: 60%;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            z-index: 3;
        }

        .footer-container {
            position: absolute;
            bottom: 1%;
            width: fit-content;

            .footer-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5px;
                height: 100%;
                width: 100%;
                color: #ddd;

                .lbl {
                    display: flex;
                    align-items: center;
                    padding: 1.25%;
                    font-size: 0.85em;
                    font-weight: 600;
                    width: 100%;
                    white-space: nowrap;
                }

                .lbl-max-bet {
                    color: gold;
                }

                .id-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    .lbl-id {
                        display: flex;
                        align-items: center;
                        padding: 1.25%;
                        width: 100%;
                        white-space: nowrap;
                    }
                }
            }
        }

        .total-bet {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px 10px;
            left: 1%;
            .lbl {
                justify-content: flex-start;
            }
        }

        .max-bet {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px 10px;
            right: 1%;
            .lbl {
                justify-content: flex-end;
            }
        }

        .bet-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0.8);
            height: 80%;
            max-height: 500px;
            width: 60cqw;
            max-width: 700px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 3px;
            will-change: auto;
            transition: all 0.5s linear;

            .bet-panel-btn-wrapper {
                background-color: rgba(0, 0, 0, 0.5);
                padding: 2px;
                border-radius: 4px;
                width: 100%;
                height: 15%;
                will-change: auto;
                transition: all 1s linear;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                transition: all 1s 0.5s linear;

                .chips-set-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                }
            }

            .jackpot-info-icon {
                position: absolute;
                background-color: rgba(0, 0, 0, 0.8);
                width: 10%;
                height: 9%;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0%;
                left: 101%;
                z-index: 100;
                font-size: 0.9rem;
                cursor: pointer;
                color: white;
                font-size: 1.2rem;
            }

            .jackpot-info {
                background-color: rgba(0, 0, 0, 0.8);
                font-size: 0.8rem;
                color: white;
                padding: 10px;
                border-radius: 10px;
                display: flex;
                gap: 20px;
                flex-direction: row;
                justify-content: center;
                position: absolute;
                top: 10%;
                right: -29%;
                z-index: 100;
                visibility: hidden;
                .bet-rate {
                    color: gold;
                    font-weight: 600;
                }
            }

            .jackpot-info.true {
                visibility: visible;
            }

            .bet-panel-container {
                position: relative;
                width: 100%;
                height: 60%;
                will-change: auto;
                transition: all 1.2s ease;

                .bet-panel {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 1;
                    z-index: 2;
                    transition: all 0.5s ease;

                    &.false {
                        opacity: 0;
                        z-index: 1;
                    }
                }

                .btn-bet-panel-method {
                    position: absolute;
                    top: 1%;
                    left: 101%;
                    padding: 5px;
                    font-size: 1.5em;
                    transition: all 1s linear;
                    box-shadow: 0px 0px 0px 1px #8c8c8c inset;
                    background-color: rgba(0, 0, 0, 0.5);
                    border-radius: 4px;
                    color: #ddd;
                    filter: brightness(0.8);

                    :is(&:hover, &:active) {
                        filter: brightness(1);
                    }
                }

                .banker-card-wrapper {
                    position: absolute;
                    top: calc(100% / 5.5);
                    right: 101%;
                    width: 30%;
                    height: 25%;

                    .title {
                        position: absolute;
                        top: 100%;
                        width: 100%;
                        color: #fff;
                        background-color: rgba(0, 0, 0, 0.5);
                        text-align: center;
                    }
                }
            }

            .road-maps-statistic-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 2%;
                height: 8%;
                width: 100%;
                background-color: rgb(38, 38, 38, 0.7);
                border-radius: 4px;
                transition: all 1s 0.5s linear;

                .statistic-count {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    height: 100%;

                    .lbl {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                        height: 100%;
                        color: #ddd;
                        font-size: 0.7em;
                        font-weight: 700;

                        .lbl-dot {
                            height: 65%;
                        }
                    }
                }
            }

            .road-maps-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 4px;
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                height: 100%;
                max-height: 30%;
                transition: all 1s 0.5s linear;

                .road-1 {
                    width: 100%;
                    height: 100%;
                }
            }

            &.stop-bet {
                top: 100%;
                transition: all 1s 0.5s linear;
                transform: translate(-50%, -50%) scale(0.75);
                transform-origin: top;
                opacity: 0.75;

                .btn-bet-panel-method {
                    opacity: 0;
                }

                .bet-panel-btn-wrapper {
                    height: 0 !important;
                    overflow: hidden;
                    opacity: 0;
                    transition: all 0.5s linear;
                }

                .road-maps-statistic-container {
                    height: 0 !important;
                    overflow: hidden;
                    opacity: 0;
                    transition: all 0.5s linear;
                }
                .road-maps-container {
                    height: 0 !important;
                    overflow: hidden;
                    opacity: 0;
                    transition: all 0.5s linear;
                }
            }
        }
    }
}
