.game-history {
  .ant-typography {
    color: #ddd;
  }
  .ant-empty {
    background-color: black;
  }
  .ant-table {
    color: #ddd;
    background: black;
    border: 1px solid #ddd;

    .ant-table-row-expand-icon {
      background-color: black;

      &:hover {
        color: gold;
      }
    }

    .ant-table-tbody {
      .ant-table-cell-row-hover {
        background: black;
      }
    }
  }

  .ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
    background-color: black;
  }
  .ant-table-thead > tr > th {
    background: black;
    color: goldenrod;
    padding: 8px;
  }

  .ant-table-thead > tr > td {
    background: black;
  }

  .ant-table-tbody > tr > td {
    padding: 8px;
  }

  .ant-table-summary > tr > td {
    background: black;
    padding: 8px;
  }

  .ant-pagination {
    color: #ddd;

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      .ant-pagination-item-link {
        background-color: black;
        color: #ddd;

        .ant-pagination-item-link-icon {
          color: #ddd;
        }

        .ant-pagination-item-ellipsis {
          color: #ddd;
        }
      }
    }

    .ant-pagination-item {
      background-color: black;
      border: 1px solid #ddd;

      a {
        color: #ddd;
      }
    }

    .ant-pagination-item-active {
      border-color: goldenrod;

      a {
        color: goldenrod;
      }
    }
  }
}
