.banker-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;

  .card {
    width: calc((100% - 4%) / 3);
    height: 100%;
  }
}

.banker-cards.false {
  .card:first-child {
    margin-right: 4%;
  }
}
