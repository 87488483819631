.sicbo-action-message {
  position: relative;
  font-size: 1em;
  font-weight: 600;
  border-radius: 12px;
  padding: 1%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.sicbo-action-message::before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, transparent, white);
  position: absolute;
  animation: myAnimate 4s linear infinite;
  top: -50%;
  left: 50%;
  transform-origin: bottom left;
}

.sicbo-action-message::after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(white, transparent, transparent);
  position: absolute;
  animation: myAnimate 4s linear infinite;
  top: 50%;
  left: -50%;
  transform-origin: top right;
}

@keyframes myAnimate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.action-message-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  backdrop-filter: blur(30px);
  z-index: 2;
}

.result-message-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  backdrop-filter: blur(30px);

  .dice-wrapper {
    width: 7%;
    aspect-ratio: 1/1;
  }
}

.win-loss-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(30px);
  color: #cfc547;
  text-shadow: 0px 11px 10px rgba(81, 67, 21, 0.8);
}
