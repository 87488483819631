.sicbo-v-bet-panel {
  $grid: 24;

  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 100%;
  height: 100%;
  font-family: "Poppins-Bold";
  container-type: inline-size;
  overflow: hidden;

  .lbl-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% / 6 * 1);
    background-color: #bfb08b;
    font-size: 3cqw;
    border-radius: 4px;
  }
  .btn-bet-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #e7e0d1;
    font-size: 3.5cqw;
    text-align: center;
    border-radius: 4px;

    .chip-placed {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: 80%;
      font-size: 3.5cqw;
    }
  }

  .bet-items-01 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1px;
    width: 100%;
    height: calc(100% / $grid * 4);
    overflow: hidden;

    .bet-small-odd-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 1px;
      width: calc(100% / 6 * 2.5);
      height: 100%;

      .bet-small-odd {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1px;
        width: 100%;
        height: calc(100% / 6 * 5);

        .bet-small {
          width: calc(100% / 3 * 2) !important;

          .chip-placed {
            width: 56%;
            height: 56%;
          }
        }
        .bet-odd {
          width: calc(100% / 3 * 1) !important;
          writing-mode: vertical-rl;

          .chip-placed {
            width: 70%;
            height: 70%;
            writing-mode: horizontal-tb;
          }
        }
      }
    }

    .bet-any-triple-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1px;
      width: calc(100% / 6 * 1);
      height: 100%;

      .bet-any-triple {
        height: calc(100% / 6 * 5);

        .chip-placed {
          width: 60%;
          height: 60%;
        }
      }
    }

    .bet-big-even-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1px;
      width: calc(100% / 6 * 2.5);
      height: 100%;

      .bet-big-even {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1px;
        width: 100%;
        height: calc(100% / 6 * 5);

        .bet-big {
          width: calc(100% / 3 * 2) !important;

          .chip-placed {
            width: 56%;
            height: 56%;
          }
        }
        .bet-even {
          width: calc(100% / 3 * 1) !important;
          writing-mode: vertical-rl;

          .chip-placed {
            width: 70%;
            height: 70%;
            writing-mode: horizontal-tb;
          }
        }
      }
    }
  }

  .bet-items-02 {
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: 100%;
    height: calc(100% / $grid * 3);

    .lbl-title {
      height: calc(100% / 6 * 2);
    }

    .bet-double {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1px;
      width: 100%;
      height: calc(100% / 6 * 4);
      overflow: hidden;

      .btn-double {
        justify-content: space-around;
        width: calc(100% / 6);
        height: 100%;

        .dice-wrapper {
          height: 70%;
          aspect-ratio: 1 / 1;
        }
      }
    }
  }

  .bet-items-03 {
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: 100%;
    height: calc(100% / $grid * 3.5);

    .lbl-title {
      height: calc(100% / 6 * 1.2);
    }

    .bet-triple {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1px;
      width: 100%;
      height: calc(100% / 6 * 4.8);
      overflow: hidden;

      .btn-triple {
        flex-direction: column;
        justify-content: space-around;
        width: calc(100% / 6);

        .dice-wrapper {
          height: 30%;
          aspect-ratio: 1 / 1;
        }

        .chip-placed {
          width: 55%;
          height: 55%;
        }
      }
    }
  }

  .bet-items-04 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: calc(100% / $grid * 4);
    width: 100%;
    overflow: hidden;

    .btn-point {
      flex-direction: column;
      width: calc(100% / 7 * 0.98);
      height: 48%;
    }
  }

  .bet-items-05 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1px;
    height: calc(100% / $grid * 7);
    width: 100%;
    overflow: hidden;

    .bet-two-dice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      height: calc(100% / 6 * 5);
      width: 100%;

      .btn-two-dice {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: calc(100% / 5 * 0.98);
        height: calc(100% / 3 * 0.97);

        .dice-wrapper {
          height: 70%;
          aspect-ratio: 1/1;
        }
      }
    }
  }

  .bet-items-06 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1px;
    height: calc(100% / $grid * 4.5);
    width: 100%;
    overflow: hidden;

    .lbl-title-wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: calc(100% / 6 * 1.5);
      background-color: #bfb08b;
      border-radius: 4px;

      .lbl-title {
        background-color: transparent;
        height: 100%;
      }
    }

    .bet-dice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      height: calc(100% / 6 * 4.5);
      width: 100%;

      .btn-dice {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: calc(100% / 3 * 0.99);
        height: calc(100% / 2 * 0.97);
        font-size: 5cqw;

        .dice-wrapper {
          height: 80%;
          aspect-ratio: 1 / 1;
        }
      }
    }
  }
}
