.bet-panel-four-seasons {
  height: 100%;
  width: 100%;
  color: #ddd;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .banker-player-jackpot-container {
    display: flex;
    justify-content: space-between;
    height: 14%;
    margin-bottom: 1px;

    .banker-jackpot-container {
      height: 100%;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(100% / 4);
      background: linear-gradient(
        to top,
        rgb(135, 104, 0, 0.7),
        rgb(173, 139, 0, 0.7)
      );
      box-shadow: 0px 0px 0px 1px #8c8c8c inset;
      font-size: 0.7em;
      cursor: pointer;

      .chip-placed {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 90%;
      }

      .jackpot-info-icon {
        position: absolute;
        top: 10%;
        right: 5%;
        font-size: 1rem;
        font-weight: 600;
        color: white;
      }

      &.win {
        animation: light 1.5s infinite ease-in-out;
        @keyframes light {
          0% {
            filter: brightness(1);
          }
          50% {
            filter: brightness(3);
          }
          100% {
            filter: brightness(1);
          }
        }
      }
    }

    .player-jackpot-container {
      height: 100%;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(100% / 4);
      background: linear-gradient(
        to top,
        rgb(135, 104, 0, 0.7),
        rgb(173, 139, 0, 0.7)
      );
      box-shadow: 0px 0px 0px 1px #8c8c8c inset;
      font-size: 0.7em;
      cursor: pointer;

      .chip-placed {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 90%;
      }

      &.win {
        animation: light 1.5s infinite ease-in-out;
        @keyframes light {
          0% {
            filter: brightness(1);
          }
          50% {
            filter: brightness(3);
          }
          100% {
            filter: brightness(1);
          }
        }
      }
    }
  }

  .bet-items-container {
    font-size: 0.7em;
    height: 86%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bet-items {
      width: calc(100% / 3.03);
      height: 100%;
      display: flex;
      flex-direction: column;

      .progress {
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: calc(100% / 5.5 * 1.5);
        box-shadow: 0px 0px 0px 1px #8c8c8c inset;
        background-color: rgba(0, 0, 0, 0.5);

        .ant-progress-text {
          color: #fff;
        }

        .seat-amount {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 5px;
          max-width: 60%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 0.5em;
        }

        .card-result {
          position: absolute;
          right: 5%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1%;
          height: 80%;
          aspect-ratio: 3/1;

          .card {
            width: calc((100% - 4%) / 3);
            height: 100%;
          }

          .card:first-child.false {
            margin-right: 7.5%;
          }
        }
      }

      .auto-container.banker {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .auto-title {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 50%;
          height: 100%;

          background: linear-gradient(
            to top,
            rgb(63, 10, 15, 0.7),
            rgb(98, 4, 10, 0.7)
          );
          box-shadow: 0px 0px 0px 1px #8c8c8c inset;

          .chip-placed {
            position: absolute;
            height: 90%;
            max-height: 40px;
            width: 19%;
          }
        }

        .btn-bet-container {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          width: 50%;
          height: 100%;

          .btn-bet {
            position: relative;
            box-shadow: 0px 0px 0px 1px #8c8c8c inset;
            background-color: rgba(0, 0, 0, 0.5);
            height: 50%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &.win {
              animation: light 1.5s infinite ease-in-out;
              @keyframes light {
                0% {
                  filter: brightness(1);
                }
                50% {
                  filter: brightness(3);
                }
                100% {
                  filter: brightness(1);
                }
              }
            }
          }

          .chip-placed {
            background-color: rgba(0, 0, 0);
            position: absolute;
            top: 20%;
            left: 20%;
            transform: translate(-50%, -50%);
            color: gold;
          }
        }
      }

      .auto-container.player {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .auto-title {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 50%;
          height: 100%;
          background: linear-gradient(
            to top,
            rgb(34, 37, 54, 0.7),
            rgb(13, 40, 78, 0.7)
          );
          box-shadow: 0px 0px 0px 1px #8c8c8c inset;

          .chip-placed {
            position: absolute;
            height: 90%;
            max-height: 40px;
            width: 19%;
          }
        }

        .btn-bet-container {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          width: 50%;
          height: 100%;

          .btn-bet {
            position: relative;
            box-shadow: 0px 0px 0px 1px #8c8c8c inset;
            background-color: rgba(0, 0, 0, 0.5);
            height: 50%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &.win {
              animation: light 1.5s infinite ease-in-out;
              @keyframes light {
                0% {
                  filter: brightness(1);
                }
                50% {
                  filter: brightness(3);
                }
                100% {
                  filter: brightness(1);
                }
              }
            }
          }

          .chip-placed {
            background-color: rgba(0, 0, 0);
            z-index: 10;
            position: absolute;
            color: gold;
            top: 20%;
            left: 20%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
