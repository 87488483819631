#game-layout {
    width: 100dvw;
    width: 100vw;
    height: 100dvh;
    height: 100vh;
    user-select: none;
    background-color: black;
    overflow: hidden;
}

::-webkit-scrollbar {
    height: 2px;
    width: 2px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #8c8c8c;
    border-radius: 12px;
}
