.type-select-modal {
    .ant-modal-content {
        background-color: transparent;
        padding: 0;
    }

    .type-select-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .type {
            width: calc(100% / 3.1);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

@media only screen and (orientation: portrait) {
    .type-select-modal {
        .type-select-container {
            flex-direction: column;
        }
    }
    @media only screen and (min-width: 1px) {
        .type-select-modal {
            .type-select-container {
                .type {
                    min-width: 300px;
                }
            }
        }
    }
    @media only screen and (min-width: 768px) {
        .type-select-modal {
            .type-select-container {
                .type {
                    min-width: 450px;
                }
            }
        }
    }
    @media only screen and (min-width: 1000px) {
        .type-select-modal {
            .type-select-container {
                .type {
                    min-width: 600px;
                }
            }
        }
    }
}

@media only screen and (orientation: landscape) {
    .type-select-modal {
        .type-select-container {
            flex-direction: row;
        }
    }
}
