.h-loading-container {
    background-image: url("../../assets/other/loading2.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.v-loading-container {
    background-image: url("../../assets/other/vloading.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.loader-container {
    background-image: url("../../assets/other/load.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
    animation: shine 2s forwards ease-in-out infinite;
}

@keyframes shine {
    0% {
        filter: brightness(1);
    }
    50% {
        filter: brightness(2);
    }
    100% {
        filter: brightness(1);
    }
}
