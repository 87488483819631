.error-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    height: 100vh;
    width: 100dvw;
    width: 100vw;
    background: linear-gradient(to top, #1c1c24, #454561);

    .ant-result {
        .ant-result-title {
            color: white;
        }
        .ant-result-subtitle {
            color: #8c8c8c;
        }
    }
}

.login-again {
    width: 100dvw;
    width: 100vw;
    height: 100dvh;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;

    .ant-result {
        .ant-result-title {
            color: #ddd;
        }

        .ant-result-subtitle {
            color: #8c8c8c;
        }
    }

    .ant-statistic {
        .ant-statistic-title {
            color: #ddd;
        }
        .ant-statistic-content {
            color: #ddd;
        }
    }
}
