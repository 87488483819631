// .login-bg {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100vh;
//     width: 100vw;
//     background-image: url("../../assets/lobby/bg.png");
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
// }

.login-bg {
    width: 100dvw;
    width: 100vw;
    height: 100dvh;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url("../../assets/lobby/bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .setting {
        width: 5%;
        position: absolute;
        top: 5%;
        right: 5%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .login-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;

        .logo {
            width: 60%;
            // height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 100%;
            }
        }

        .login-form {
            width: 60%;
            height: 70%;
            background-image: url("../../assets/login/form-bg.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .login-form-wrapper {
                width: 75%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .ant-form-item {
                    margin-bottom: 10px;
                    width: 80%;
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;

                    .ant-btn {
                        background: black;
                        border: 1px solid gold;
                        color: gold;
                    }
                }

                .login-btn {
                    width: 50%;
                    height: 15%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }

            .ant-input-affix-wrapper {
                background-color: transparent;
                border: 0;
                // border-bottom: 2px solid gold;
                color: gold;

                .ant-input-prefix {
                    background-color: black;
                    padding: 8px;
                }

                .ant-input {
                    background-color: #ddd;
                    color: black;
                    padding: 8px;

                    &::placeholder {
                        color: black;
                    }
                }
            }
        }
    }
}

@media only screen and (orientation: portrait) {
    @media only screen and (min-width: 1px) {
        .login-bg {
            .login-wrapper {
                .logo {
                    width: 70%;
                }
                .login-form {
                    width: 90%;
                    height: 50%;
                    .login-form-wrapper {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .login-bg {
            .login-wrapper {
                .logo {
                    width: 50%;
                }
                .login-form {
                    width: 70%;
                    .login-form-wrapper {
                        width: 100%;

                        .login-btn {
                            width: 40%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (orientation: landscape) {
    @media only screen and (min-width: 1px) {
        .login-bg {
            .login-wrapper {
                .logo {
                    width: 30%;
                }
                .login-form {
                    height: 60%;
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .login-bg {
            .login-wrapper {
                .login-form {
                    width: 40%;

                    .login-form-wrapper {
                        width: 100%;

                        .login-btn {
                            width: 40%;
                        }
                    }
                }
            }
        }
    }
}
