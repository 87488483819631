.vertical-dream-catcher {
    $Grid: 25;
    $MainWidth: calc(100vw - 8px);

    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    max-width: 100%;
    height: 100%;
    // background-image: url("../../../assets/lobby/asd.jpg");
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;

    .header-container {
        padding: 5px 4px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: calc(100% / $Grid * 1);
        width: 100%;
        color: gold;
        font-size: 1em;
        font-weight: 600;
        font-style: italic;
        text-decoration: underline;

        .header-title {
            height: 100%;
            white-space: nowrap;
            display: flex;
            justify-content: start;
            align-items: center;
            font-size: 1em;
            font-weight: 600;
            text-decoration: underline;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 12px;
            padding: 2px;
            gap: 10px;

            .header-btn {
                height: 100%;
                aspect-ratio: 1/1;
                cursor: pointer;
            }
        }

        .header-menu {
            height: 100%;
        }
    }

    .live-streaming-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: start;
        width: 100%;
        height: calc(100% / $Grid * 6);

        .id-wrapper {
            position: absolute;
            bottom: 5px;
            left: 10px;
            background-color: rgb(0, 0, 0, 0.5);
            padding: 5px;
            border-radius: 10px;

            .uid {
                color: #ddd;
                font-size: 0.8em;
                font-weight: 700;
            }
        }

        .timer-container {
            position: absolute;
            top: 12%;
            right: 2%;
            height: 30%;
            aspect-ratio: 1/1;
            z-index: 2;
        }

        .banker-card-wrapper {
            position: absolute;
            top: 20%;
            left: 1%;
            width: 28%;
            height: 22%;

            .title {
                position: absolute;
                bottom: 100%;
                width: 100%;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.5);
                text-align: center;
            }
        }
    }

    .bet-panel-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: calc(100% / $Grid * 8.2);
    }

    .action-msg-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 8%;
        z-index: 2;

        :is(&.undefined) {
            visibility: hidden;
        }

        :is(&.startBet, &.stopBet, &.nextRound, &.lastGame, &.cancelRound) {
            animation: fade-anim 3s ease-in-out forwards;

            @keyframes fade-anim {
                50% {
                    opacity: 1;
                    display: block;
                }
                100% {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }

    .chips-set-container {
        padding: 0px 4px;
        height: calc(100% / $Grid * 2);
        max-height: calc(100% / $Grid * 2);
    }

    .road-map-statistic-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2%;
        height: calc(100% / $Grid * 1);
        background-color: rgb(38, 38, 38, 0.7);
        border-radius: 4px;

        .statistic-count {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            height: 100%;

            .lbl {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                height: 100%;
                color: #ddd;
                font-size: 0.7em;
                font-weight: 700;

                .lbl-dot {
                    height: 65%;
                }
            }
        }
    }

    .road-maps-container {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        min-width: 100%;
        height: calc(100% / $Grid * 5.5);
        overflow-x: auto;

        .road-01 {
            width: fit-content;
            min-width: 100%;
            overflow-x: auto;
            height: 100%;
        }
    }

    .footer-container {
        padding: 0px 4px 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: calc(100% / $Grid * 1);

        .container-01 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            height: 100%;
            width: 70%;
            color: #ddd;

            .lbl {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 1.25%;
                border: 2px solid #ddd;
                border-radius: 25px;
                font-size: 0.7em;
                font-weight: 600;
                width: 55%;
                max-width: 250px;
                white-space: nowrap;
            }
        }

        .container-02 {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 30%;
            color: gold;
            font-size: 0.7em;
            font-weight: 600;
        }
    }

    .tbl-btn-container {
        position: absolute;
        bottom: 5%;
        right: 3%;
        height: 5%;
        aspect-ratio: 1/1;
        z-index: 1;
        cursor: pointer;
        opacity: 0.4;
        transition: all 0.5s ease;

        :is(&:hover, &:active) {
            opacity: 1;
        }

        .tbl-btn-wrapper {
            width: 100%;
            height: 100%;
        }
    }
}
