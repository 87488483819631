.four-seasons-road {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-size: 0.9rem;

  &.road-01 {
    $RowCount: 11;
    border-radius: 2px;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .row-item {
      width: 100% / $RowCount;
      height: 100%;

      .col-item {
        width: 100%;
        height: calc(100% / 4);
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  @media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
    .four-seasons-road {
      font-size: 0.7rem;
    }
  }
}
