.menu-drawer {
    .ant-drawer-content-wrapper {
        .ant-drawer-content {
            background-color: rgba(0, 0, 0, 0.7);
            border: 1px solid gold;
            border-radius: 8px;

            .ant-drawer-wrapper-body {
                .ant-drawer-header {
                    padding: 10px;
                    .ant-drawer-header-title > .ant-drawer-title,
                    .ant-drawer-close {
                        color: gold;
                    }
                }
                .ant-drawer-body {
                    padding: 15px;
                    color: gold;

                    .menu-item {
                        .ant-card {
                            color: gold;
                            background-color: #323232;
                            border: 0;
                            font-weight: 600;

                            .ant-card-body {
                                padding: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
