.game-hitsory-detail {
  .dice-result-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;

    .dice-wrapper {
      width: 10%;
      aspect-ratio: 1/1;

      svg {
        width: 100%;
        height: 100%;
        max-height: 40px;
      }
    }
  }

  .ant-table {
    color: #bfbfbf;
    background: #1a1a1a;
    border: 1px solid #777070;

    .ant-table-row-expand-icon {
      background-color: #1a1a1a;
    }
  }
  .ant-table-thead > tr > th {
    background: black;
    color: white;
    padding: 2px;
  }

  .ant-table-tbody > tr > td {
    padding: 2px;
  }

  .ant-table-summary > tr > td {
    background: #1a1a1a;
    color: gold;
    padding: 2px;
  }
}
