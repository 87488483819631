.three-picture-bet-panel {
  height: 100%;
  width: 100%;
  color: #ddd;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .win {
    animation: light 1.5s infinite ease-in-out;
    @keyframes light {
      0% {
        filter: brightness(1);
      }
      50% {
        filter: brightness(3);
      }
      100% {
        filter: brightness(1);
      }
    }
  }

  .traditional-bet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .traditional-bet-item-wrapper-TP {
      display: flex;
      justify-content: space-between;
      height: 17%;
      margin-bottom: 1px;

      .TP-items {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: calc(100% / 4.05);
        background: linear-gradient(to top, rgb(135, 104, 0, 0.7), rgb(173, 139, 0, 0.7));
        box-shadow: 0px 0px 0px 1px #8c8c8c inset;
        font-size: 0.7em;
        cursor: pointer;

        .chip-placed {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 90%;
        }
      }
    }

    .traditional-bet-item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 81%;
      overflow: hidden;

      .traditional-bet-item {
        height: 100%;
        width: calc(100% / 3.05);

        .progress {
          position: relative;
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 100%;
          height: calc(100% / 5.5 * 1.5);
          box-shadow: 0px 0px 0px 1px #8c8c8c inset;
          background-color: rgba(0, 0, 0, 0.5);

          .ant-progress-text {
            color: #fff;
          }

          .seat-amount {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 5px;
            max-width: 60%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 0.5em;
          }

          .card-results {
            position: absolute;
            right: 5%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1%;
            height: 80%;
            aspect-ratio: 3/1;
            max-width: 90%;

            .card-item {
              width: calc((100% - 4%) / 3);
              height: 100%;
            }
          }
        }

        .btn-bet-item {
          position: relative;
          float: left;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          padding: 0 5px;
          width: 100%;
          height: calc(100% / 5.5);
          box-shadow: 0px 0px 0px 1px #8c8c8c inset;
          font-size: 0.8em;
          overflow: hidden;
          cursor: pointer;

          .chip-placed {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 55%;
            height: 80%;
          }

          &.T {
            justify-content: center;
            height: calc(100% / 5.5 * 1.5);
            font-size: 0.7em;
            background: linear-gradient(to top, rgb(27, 46, 8, 0.7), rgb(1, 78, 2, 0.7));
          }
          &.BW {
            flex-direction: column;
            height: calc(100% / 5.5 * 2.5);
            width: 50%;
            font-size: 0.7em;
            background: linear-gradient(to top, rgb(63, 10, 15, 0.7), rgb(98, 4, 10, 0.7));
          }
          &.PW {
            z-index: 10;
            flex-direction: column;
            height: calc(100% / 5.5 * 2.5);
            width: 50%;
            font-size: 0.7em;
            background: linear-gradient(to top, rgb(34, 37, 54, 0.7), rgb(13, 40, 78, 0.7));
          }
        }
      }
    }
  }

  .more-bet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .more-bet-item-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: calc(100% / 2.02);
      width: 100%;

      .lbl-title-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        font-size: 0.7em;
      }

      .bet-item-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;

        .bet-items {
          display: flex;
          flex-wrap: wrap;
          width: calc(100% / 2.05);
          height: 100%;
          border-radius: 4px;
          overflow: hidden;

          .btn-bet-item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100% / 5);
            height: calc(100% / 2);
            box-shadow: 0px 0px 0px 1px #8c8c8c inset;
            background-color: rgba(0, 0, 0, 0.3);
            overflow: hidden;
            cursor: pointer;

            .chip-placed {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 80%;
            }
          }
        }
      }
    }
  }
}
