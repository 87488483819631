.lobby-header {
    background: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0));
    padding: 5px 20px;

    .lobby-date-user {
        color: #bfbfbf;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        gap: 20px;

        .user {
            display: flex;
            gap: 5px;
        }
    }

    .ant-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .ant-avatar {
        cursor: pointer;
    }

    .lobby-menu {
        img {
            max-width: 100%;
            max-height: 100%;
        }
        .ant-btn {
            background: transparent;
            color: #bfbfbf;
            border: 1px solid #bfbfbf;
            &:hover {
                background: #34343d;
            }
        }

        .ant-avatar {
            background: transparent;
            color: #bfbfbf;
            border: 1px solid #bfbfbf;
        }

        .ant-card {
            background: transparent;
            border: 0;
            color: #bfbfbf;
            font-size: large;

            .ant-card-body {
                padding: 0px;
            }

            .ant-typography {
                margin: 0px;
            }
        }
    }

    .gold {
        .ant-typography {
            color: goldenrod;
        }
    }

    .smoke {
        .ant-typography {
            color: #bfbfbf;
        }
    }
}

.menu {
    .ant-menu {
        border-inline-end: none !important;
        background: transparent;

        .ant-menu-item {
            margin: 0;
            width: 100%;

            &:hover {
                background: #434356 !important;
            }
        }

        .ant-menu-item-selected {
            background: transparent;
        }
    }

    .ant-typography {
        color: #bfbfbf;
        margin: 0;
    }
}

.popover {
    .ant-popover-inner {
        padding: 5px;
        background-color: #39394d;
    }
}

.h-lobby-header {
    width: 100%;
    height: 10%;
    min-height: 40px;
    max-height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px 10px;

    .lobby-left-wrapper {
        width: calc(100% / 3);
        height: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        color: #bfbfbf;
        gap: 10px;

        .date-time {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .active-player {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .lobby-center-wrapper {
        width: calc(100% / 3);
        height: 100%;
        .logo {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .lobby-right-wrapper {
        width: calc(100% / 3);
        height: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 10px;

        .balance {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;
            color: gold;
            font-size: 1em;
            font-weight: 600;
            text-wrap: nowrap;
        }

        .lobby-menu {
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            gap: 5px;

            .sound-btn {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .suggestion-btn {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .lang-btn {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .logout-btn {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
}

@media screen and (orientation: landscape) {
    @media screen and (min-width: 1200px) {
        .h-lobby-header {
            .lobby-right-wrapper {
                .lobby-menu {
                    height: 70%;
                }
            }
        }
    }
}

.v-lobby-header {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    .lobby-left-wrapper {
        width: calc(100% / 2);
        height: 100%;
        .lobby-left-upper-wrapper {
            width: 100%;
            height: calc(100% / 3 * 2);
            .logo {
                width: 100%;
                height: 100%;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        .lobby-left-lower-wrapper {
            width: 100%;
            height: calc(100% / 3);
            display: flex;
            justify-content: space-around;
            align-items: center;
            color: #bfbfbf;
            gap: 5px;

            .date-time {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-wrap: nowrap;
            }

            .active-player {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-wrap: nowrap;
            }
        }
    }
    .lobby-right-wrapper {
        width: calc(100% / 2);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .lobby-right-upper-wrapper {
            width: 100%;
            height: calc(100% / 2);

            .balance {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: end;
                align-items: center;
                gap: 2px;
                color: gold;
                font-size: 1em;
                font-weight: 600;
                text-wrap: nowrap;
            }
        }
        .lobby-right-lower-wrapper {
            width: 100%;
            height: calc(100% / 2);
            display: flex;
            justify-content: end;
            align-items: center;
            gap: 5px;

            .sound-btn {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .suggestion-btn {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .lang-btn {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .logout-btn {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
}
