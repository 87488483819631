.game-hitsory-detail {
    .roulette-road {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .road-wrapper {
            width: 10%;
        }
    }
    .ant-card {
        background-color: #1a1a1a;

        .ant-card-body {
            padding: 10px;
        }
    }

    .ant-table {
        color: #bfbfbf;
        background: #1a1a1a;
        border: 1px solid #777070;

        .ant-table-row-expand-icon {
            background-color: #1a1a1a;
        }
    }
    .ant-table-thead > tr > th {
        background: black;
        color: white;
        padding: 2px;
    }

    .ant-table-tbody > tr > td {
        padding: 2px;
    }

    .ant-table-summary > tr > td {
        background: #1a1a1a;
        color: gold;
        padding: 2px;
    }

    .div-rotate-right {
        transform: rotate(90deg) translateY(8px);
    }

    .div-rotate-left {
        transform: rotate(-90deg) translateY(8px);
    }
}
