.three-picture-road {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;

  &.road-01 {
    $RowCount: 16;
    border-radius: 2px;
    overflow: hidden;

    .row-item {
      width: 100% / $RowCount;
      height: 100%;

      .col-item {
        width: 100%;
        height: calc(100% / 4);
      }
    }
  }
}
