.lobby-layout {
    position: relative;
    height: 100dvh;
    height: 100vh;
    width: 100dvw;
    width: 100vw;
    // overflow: hidden;
    overflow-y: auto;
    overflow-x: hidden;

    .lobby-content {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
    }
}

// @media only screen and (orientation: portrait) {
//     .lobby-layout {
//         height: 100vw;
//         width: 100vh;
//         transform: translate(100vw, 0) rotate(90deg);
//         transform-origin: top left;
//     }
// }
