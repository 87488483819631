.baccarat-road {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;

  &.road-01 {
    $RowCount: 6;
    border-radius: 2px;
    overflow: hidden;

    .row-item {
      width: 100% / $RowCount;
      height: 100%;

      .col-item {
        width: 100%;
        aspect-ratio: 1 / 1;
      }
    }
  }

  &.road-02 .row-item {
    $RowCount: 25;
    $ColCount: 6;

    width: 100% / $RowCount;
    height: 100%;

    .col-item {
      width: 100%;
      height: calc(100% / $ColCount);
    }
  }

  :is(&.road-03 .row-item, &.road-04 .row-item, &.road-05 .row-item) {
    $RowCount: 20;
    $ColCount: 6;

    width: calc(100% / $RowCount);
    height: 100%;

    .col-item {
      width: 100%;
      height: calc(100% / $ColCount);
    }
  }
}
