.coming-soon-bg {
    width: 100dvw;
    width: 100vw;
    height: 100dvh;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;

    .ant-result-title {
        color: white;
    }

    .ant-result-subtitle {
        color: #8c8c8c;
    }

    .ant-statistic {
        .ant-statistic-title {
            color: white;
        }
        .ant-statistic-content {
            color: white;
        }
    }
}
