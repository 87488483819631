.road-map-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
    // border-radius: 4px;
    border: 1px solid #efc3b0;
    color: #efc3b0;
    font-family: "Times New Roman", Times, serif;
    font-weight: 600;

    &.green {
        background-color: rgb(22, 105, 88, 0.7);
    }

    &.red {
        background-color: rgb(181, 28, 18, 0.7);
    }

    &.black {
        background-color: rgb(33, 33, 36, 0.7);
    }

    @media only screen and (min-width: 1px) {
        font-size: 0.8em;
    }
    @media only screen and (min-width: 800px) {
        font-size: 1em;
    }
    @media only screen and (min-width: 1000px) {
        font-size: 1.3em;
    }
    @media only screen and (min-width: 1500px) {
        font-size: 1.8em;
    }
}
