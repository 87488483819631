.chip-setting-modal {
    .ant-modal-content {
        background-color: rgb(0, 0, 0, 0.7);
        // padding: 12px;

        .ant-modal-close {
            span {
                img {
                    max-width: 100%;
                }
            }
        }
    }

    .chip-setting-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .chip-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
        }

        .agent-chips {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            row-gap: 5px;
            column-gap: 5px;

            .agent-chip {
                width: calc(100% / 4.5);
                height: 100%;
                border: 1px solid gold;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                padding: 5px;
                border-radius: 25px;

                &.true {
                    background-color: gold;
                    color: black;
                }
            }
        }

        .confirm-edit-btn {
            width: 40%;
            height: 10%;
            min-width: 150px;
            max-width: 200px;
            color: white;
            font-weight: 700;
            border: 1px solid gold;
            border-radius: 25px;
            padding: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
