.category {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow: auto;
    width: 100%;
    height: 100%;

    img {
        max-width: 60px;
        max-height: 100%;
    }
}
::-webkit-scrollbar {
    display: none;
}

@media only screen and (orientation: landscape) {
    @media only screen and (min-width: 1200px) {
        .category {
            img {
                max-width: 80px;
            }
        }
    }
}

@media only screen and (orientation: portrait) {
    .category {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: start;
    }
}
