.sicbo-h-bet-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: "Poppins-Bold";
    container-type: inline-size;
    font-weight: 600;

    .chip-placed {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        height: 80%;
        font-size: 1.5cqw;
    }

    .bet-items-01 {
        display: flex;
        align-items: center;
        width: 100%;
        height: calc(100% / 6 * 2.5);

        :is(.bet-small-odd, .bet-double, .bet-triple, .bet-all-triple, .bet-big-even) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            font-size: 2cqw;

            &.win {
                animation: light 1.5s infinite ease-in-out;
                @keyframes light {
                    0% {
                        filter: brightness(1);
                    }
                    50% {
                        filter: brightness(3);
                    }
                    100% {
                        filter: brightness(1);
                    }
                }
            }
        }

        :is(.bet-small-odd, .bet-big-even) {
            flex-direction: column;
            gap: 2px;
            width: calc(35% / 2);

            :is(.bet-small, .bet-big) {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 98%;
                height: calc(100% / 3 * 2);
                background-color: #e7e0d1;
                border-radius: 6px;
                font-weight: 700;

                &.win {
                    animation: light 1.5s infinite ease-in-out;
                    @keyframes light {
                        0% {
                            filter: brightness(1);
                        }
                        50% {
                            filter: brightness(3);
                        }
                        100% {
                            filter: brightness(1);
                        }
                    }
                }

                .lbl-point {
                    font-size: 1.5cqw;
                    font-weight: 600;
                }

                .lbl-rate {
                    font-weight: normal;
                    font-size: 1.5cqw;
                }

                .chip-placed {
                    width: 50%;
                    height: 50%;
                }
            }

            :is(.bet-odd, .bet-even) {
                position: relative;
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 98%;
                height: calc(100% / 3 * 1);
                font-weight: 600;
                background-color: #e7e0d1;
                border-radius: 6px;

                .lbl-rate {
                    font-weight: normal;
                    font-size: 1.5cqw;
                }

                &.win {
                    animation: light 1.5s infinite ease-in-out;
                    @keyframes light {
                        0% {
                            filter: brightness(1);
                        }
                        50% {
                            filter: brightness(3);
                        }
                        100% {
                            filter: brightness(1);
                        }
                    }
                }
            }
        }

        :is(.bet-all-triple) {
            position: relative;
            flex-direction: column;
            width: 15%;
            background-color: #e7e0d1;
            border-radius: 6px;

            &.win {
                animation: light 1.5s infinite ease-in-out;
                @keyframes light {
                    0% {
                        filter: brightness(1);
                    }
                    50% {
                        filter: brightness(3);
                    }
                    100% {
                        filter: brightness(1);
                    }
                }
            }

            .bet-all-triple-title {
                display: flex;
                justify-content: center;
                align-items: center;
                height: calc(100% / 3.5 * 0.5);
                font-size: 1.5cqw;
            }

            .chip-placed {
                width: 35%;
                height: 35%;
            }
        }

        :is(.bet-double) {
            flex-direction: column;
            gap: 2px;
            width: 10%;

            &.win {
                animation: light 1.5s infinite ease-in-out;
                @keyframes light {
                    0% {
                        filter: brightness(1);
                    }
                    50% {
                        filter: brightness(3);
                    }
                    100% {
                        filter: brightness(1);
                    }
                }
            }

            .bet-double-title {
                display: flex;
                justify-content: center;
                align-items: center;
                height: calc(100% / 3.5 * 0.5);
                font-size: 1.5cqw;
                background-color: #bfb08b;
                width: 98%;
                border-radius: 6px;
            }

            .bet-double-item {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 3%;
                height: calc(100% / 3.5 * 1);
                background-color: #e7e0d1;
                width: 98%;
                border-radius: 6px;
                overflow: hidden;

                .dice-wrapper {
                    width: calc(100% / 2.8 * 1);
                    aspect-ratio: 1/1;
                }
            }
        }

        :is(.bet-triple) {
            flex-direction: column;
            gap: 2px;
            width: 15%;

            &.win {
                animation: light 1.5s infinite ease-in-out;
                @keyframes light {
                    0% {
                        filter: brightness(1);
                    }
                    50% {
                        filter: brightness(3);
                    }
                    100% {
                        filter: brightness(1);
                    }
                }
            }

            .bet-triple-title {
                display: flex;
                justify-content: center;
                align-items: center;
                height: calc(100% / 3.5 * 0.5);
                font-size: 1.5cqw;
                background-color: #bfb08b;
                width: 98%;
                border-radius: 6px;
            }

            .bet-triple-item {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 3%;
                height: calc(100% / 3.5 * 1);
                background-color: #e7e0d1;
                width: 98%;
                border-radius: 6px;
                overflow: hidden;

                .dice-wrapper {
                    aspect-ratio: 1/1;
                    width: 25%;
                }
            }
        }
    }

    .bet-items-02 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
        width: 100%;
        height: calc(100% / 6 * 1);

        .BP {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: calc(100% / 14);
            height: 90%;
            font-size: 3.5cqw;
            background-color: #e7e0d1;
            border-radius: 6px;

            &.win {
                animation: light 1.5s infinite ease-in-out;
                @keyframes light {
                    0% {
                        filter: brightness(1);
                    }
                    50% {
                        filter: brightness(3);
                    }
                    100% {
                        filter: brightness(1);
                    }
                }
            }

            .lbl-rate {
                font-size: 1.5cqw;
            }
        }
    }

    .bet-items-03 {
        width: 100%;
        height: calc(100% / 6 * 1.5);

        .bet-two-dice-title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100% / 3 * 0.7);
            width: 100%;
            font-size: 1.5cqw;
            background-color: #bfb08b;
            border-radius: 6px;
        }

        .bet-two-dice {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: calc(100% / 3 * 2.2);

            .bet-two-dice-item {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5%;
                height: 98%;
                width: calc(100% / 15 * 0.95);
                background-color: #e7e0d1;
                border-radius: 6px;

                &.win {
                    animation: light 1.5s infinite ease-in-out;
                    @keyframes light {
                        0% {
                            filter: brightness(1);
                        }
                        50% {
                            filter: brightness(3);
                        }
                        100% {
                            filter: brightness(1);
                        }
                    }
                }

                .dice-wrapper {
                    aspect-ratio: 1/1;
                    height: 38%;
                }
            }
        }
    }

    .bet-items-04 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: calc(100% / 6 * 1);

        .bet-dice {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: calc(100% / 3 * 2);

            .bet-dice-item {
                position: relative;
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: calc(100% / 6 * 0.99);
                height: 100%;
                background-color: #e7e0d1;
                border-radius: 6px;
                font-weight: 600;
                font-size: 2.5cqw;

                &.win {
                    animation: light 1.5s infinite ease-in-out;
                    @keyframes light {
                        0% {
                            filter: brightness(1);
                        }
                        50% {
                            filter: brightness(3);
                        }
                        100% {
                            filter: brightness(1);
                        }
                    }
                }

                .dice-wrapper {
                    width: 20%;
                    aspect-ratio: 1/1;
                }
            }
        }

        .bet-dice-title {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4%;
            width: 100%;
            height: calc(100% / 3 * 0.9);
            font-size: 1.5cqw;
            background-color: #bfb08b;
            border-radius: 6px;

            .bet-dice-title-item {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        }
    }
}
