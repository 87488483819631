.lobby-bg {
    background-image: url("../../assets/lobby/bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
