.fs-how-to-play {
  .section {
    .title {
      font-weight: 700;
    }

    .content {
      font-size: 0.9rem;

      ul {
        padding-left: 15px;
        list-style-type: decimal;

        li {
          margin-top: 5px;
        }
      }

      table {
        font-size: 0.7rem;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        border-radius: 4px;
        border-collapse: collapse;
        overflow: hidden;

        :is(th, td) {
          padding: 5px 0;
          border: 1px solid #ddd;
        }

        td:first-child {
          padding-left: 5px;
        }

        td:not(:first-child) {
          text-align: center;
        }
      }
    }
  }
}
