::-webkit-scrollbar {
    display: none;
}
.nh-chips {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 100%;
    gap: 2px;

    .wrapper-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50%;
        width: 100%;

        .btn-function {
            width: calc(100% / 8);
            height: 100%;
        }

        .btn-function-1 {
            width: calc(100% / 8 * 2);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &.shine {
                animation: shine 1s forwards ease-in-out infinite;

                @keyframes shine {
                    0% {
                        filter: brightness(1);
                    }
                    50% {
                        filter: brightness(2);
                    }
                    100% {
                        filter: brightness(1);
                    }
                }
            }
        }

        .btn-function-3 {
            padding: 5px;
            width: calc(100% / 8 * 3);
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .ant-switch {
                width: 65%;
                background-color: transparent;
                text-wrap: nowrap;
                .ant-switch-handle {
                    &::before {
                        background-color: gold;
                    }
                }

                .ant-switch-inner {
                    background-color: rgb(0, 0, 0, 0.5);
                    border: 1px solid gold;
                    .ant-switch-inner-checked,
                    .ant-switch-inner-unchecked {
                        font-size: 10px;
                    }
                }
            }
        }
    }

    .wrapper-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
        width: 60%;

        .chips-container {
            height: 100%;
            width: 100%;

            .swiper-wrapper {
                .swiper-slide {
                    margin-right: 0 !important;
                }
            }

            .btn-chip {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
