.fs-h-review-card {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;

  .close-icon {
    position: absolute;
    right: 1%;
    top: 5%;
    color: #ddd;
    font-size: 2em;
    z-index: 1;
  }

  .all-card-set {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .content-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      .card-set {
        width: calc(100% / 3);
        cursor: pointer;

        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ddd;
          font-weight: 700;
          font-size: 1em;
        }

        .content {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          width: 100%;
          aspect-ratio: 3 / 1;

          .card-wrapper {
            width: calc(100% / 4);
            height: 100%;

            &:first-child {
              transform: translateX(50%);
            }

            &:nth-child(2) {
              transform: translateX(25%);
            }

            &:last-child {
              transform: translateX(-25%);
            }
          }
        }
      }
    }
  }

  .scratch-card-set {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 88%;
    height: 45%;
    gap: 5px;

    .result-card-top {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      .card-wrappers {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        aspect-ratio: 1 / 1.5;

        .ScratchCard__Container {
          width: 100% !important;
          height: 100% !important;
        }

        .ScratchCard__Canvas {
          width: 100% !important;
          height: 100% !important;
        }

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }

    .result-card-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      gap: 15px;

      .card-wrappers {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% / 3);
        height: 100%;
        aspect-ratio: 1 / 1.5;

        .ScratchCard__Container {
          width: 100% !important;
          height: 100% !important;
        }

        .ScratchCard__Canvas {
          width: 100% !important;
          height: 100% !important;
        }

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
