#horizontal-sicbo {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 18 / 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-spin-blur,
    .live-streaming-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .horizontal-sicbo-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow: hidden;

        .action-msg-container {
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translate(-50%, 0);
            width: 45%;
            height: 15%;
            max-width: 500px;
            max-height: 80px;
            z-index: 2;
            opacity: 0.8;

            :is(&.undefined) {
                visibility: hidden;
            }

            :is(&.startBet, &.stopBet, &.nextRound, &.lastGame, &.cancelRound) {
                animation: fade-anim 3s ease-in-out forwards;

                @keyframes fade-anim {
                    50% {
                        opacity: 1;
                        display: block;
                    }
                    100% {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }

        .timer-container {
            position: absolute;
            top: 10%;
            right: 1%;
            height: 15%;
            max-height: 80px;
            aspect-ratio: 1/1;
            z-index: 2;
        }

        .header-container {
            position: absolute;
            top: 1%;
            padding: 0 1%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 8%;
            max-height: 45px;
            width: 100%;
            color: gold;

            .header-title {
                height: 100%;
                white-space: nowrap;
                display: flex;
                justify-content: start;
                align-items: center;
                font-size: 1em;
                font-weight: 600;
                text-decoration: underline;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 12px;
                padding: 5px;
                gap: 10px;

                .header-btn {
                    height: 100%;
                    aspect-ratio: 1/1;
                    cursor: pointer;
                }
            }

            .header-menu {
                height: 100%;
            }
        }

        .chips-set-container {
            position: absolute;
            top: 50%;
            left: 0%;
            transform: translate(50%, -50%);
            height: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;

            .chips-set-wrapper {
                height: 40%;
                max-height: 250px;
            }
        }

        .tbl-btn-container {
            position: absolute;
            bottom: 12%;
            right: 1%;
            height: 10%;
            max-height: 50px;
            aspect-ratio: 1/1;
            z-index: 1;
            cursor: pointer;

            .tbl-btn-wrapper {
                width: 100%;
                height: 100%;
            }
        }

        .win-list-wrapper {
            position: absolute;
            right: 1%;
            top: 10%;
            height: 60%;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            z-index: 3;
        }

        .footer-container {
            position: absolute;
            bottom: 1%;
            width: fit-content;

            .footer-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5px;
                height: 100%;
                width: 100%;
                color: #ddd;

                .lbl {
                    display: flex;
                    align-items: center;
                    padding: 1.25%;
                    font-size: 0.85em;
                    font-weight: 600;
                    width: 100%;
                    white-space: nowrap;
                }

                .lbl-max-bet {
                    color: gold;
                }

                .id-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    .lbl-id {
                        display: flex;
                        align-items: center;
                        padding: 1.25%;
                        width: 100%;
                        white-space: nowrap;
                    }
                }
            }
        }

        .total-bet {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px 10px;
            left: 1%;
            .lbl {
                justify-content: flex-start;
            }
        }

        .max-bet {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px 10px;
            right: 1%;
            .lbl {
                justify-content: flex-end;
            }
        }

        .bet-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -40%) scale(0.95);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 3px;
            height: 80%;
            max-height: 472px;
            width: 80%;
            max-width: 944px;
            transition: all 1s linear;

            .road-map-wrapper {
                width: 100%;
                max-width: 100%;
                height: 10%;
            }

            .bet-panel-container {
                width: 100%;
                height: 75%;
                will-change: auto;
                transition: all 1s linear;
            }

            .chips-wrapper {
                width: 70%;
                max-width: 660.8px;
                height: 15%;
                opacity: 1;
                transition: all 1s linear;
            }

            &.stop-bet {
                top: 100%;
                transform: translate(-50%, -43%) scale(0.5);
                transform-origin: top;
                opacity: 0.8;

                .chips-wrapper {
                    opacity: 0;
                }
            }
        }
    }
}
