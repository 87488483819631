.table-list-cont {
    width: 100%;
    height: 100%;

    .coming-soon {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 10px;
            border: 1px solid gold;
            border-radius: 10px;
        }
    }

    .table-list-data-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .table-list-data-title {
            font-size: 1rem;
            font-weight: 500;
            color: #ffffff;
            margin-top: 20px;
            margin-bottom: 5px;
        }

        .table-table-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .roulette-table {
                padding: 10px;
                width: calc(100% / 2.1);
                height: 100%;
                background: #000000;
                border: 1px solid #c2b557;
                border-radius: 5px;
                color: #bfbfbf;
                font-weight: 500;
                font-size: 0.8rem;

                .table-table-header {
                    width: 100%;
                    height: 25px;
                    margin-bottom: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .table-table-currency {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 0.6rem;

                        .table-timer {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: 30%;
                            height: 100%;
                            gap: 2px;
                            background-color: transparent !important;

                            .timer-container {
                                width: 30%;
                                aspect-ratio: 1/1;
                                height: 100%;
                                background-color: transparent !important;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .timer-placed {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 100%;

                                    width: 100%;
                                }

                                .hour-glass {
                                    animation: rotate 3s ease-in-out infinite;
                                    display: flex;
                                }

                                @keyframes rotate {
                                    50% {
                                        transform: rotate(180deg);
                                    }
                                    100% {
                                        transform: rotate(360deg);
                                    }
                                }
                            }

                            .action-container {
                                color: #c2b557;
                                width: 70%;
                                max-width: 85px;
                                background-color: transparent !important;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                        .table-index {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: 40%;
                            gap: 5px;
                            background-color: transparent !important;
                        }

                        .table-name {
                            width: 20%;
                        }
                    }
                }

                .table-table-image {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                        // aspect-ratio: 1/1;
                    }

                    .table-table-roadmap {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        cursor: pointer;
                        width: 100%;
                        height: auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                    }
                }

                .table-table-footer {
                    width: 100%;
                    height: 10%;
                    margin-top: 10px;

                    .table-table-favorite {
                        gap: 15px;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .currency {
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }

            .table-table {
                padding: 10px;
                width: 100%;
                height: 100%;
                background: #000000;
                border: 1px solid #c2b557;
                border-radius: 5px;
                color: #bfbfbf;
                font-weight: 500;
                font-size: 0.8rem;

                .table-table-header {
                    width: 100%;
                    height: 25px;
                    margin-bottom: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .table-table-currency {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 0.8rem;
                        background-color: transparent;
                        font-weight: 600;
                        .table-timer {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: 30%;
                            height: 100%;
                            gap: 2px;
                            background-color: transparent !important;

                            .timer-container {
                                width: 30%;
                                aspect-ratio: 1/1;
                                height: 100%;
                                background-color: transparent !important;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .timer-placed {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 100%;

                                    width: 100%;
                                }

                                .hour-glass {
                                    animation: rotate 3s ease-in-out infinite;
                                    display: flex;
                                }

                                @keyframes rotate {
                                    50% {
                                        transform: rotate(180deg);
                                    }
                                    100% {
                                        transform: rotate(360deg);
                                    }
                                }
                            }

                            .action-container {
                                color: #c2b557;
                                width: 70%;
                                max-width: 110px;
                                background-color: transparent !important;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        .table-index {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: 40%;
                            gap: 5px;
                            background-color: transparent !important;
                        }

                        .table-name {
                            width: 20%;
                            max-width: 100px;
                        }
                    }
                }

                .table-table-roadmap {
                    cursor: pointer;
                    width: 100%;
                    height: 80%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    .four-seasons-road-map {
                        width: 76%;
                        height: 80%;
                    }

                    .road-maps-container {
                        width: 76%;
                        height: 100%;
                        display: flex;

                        .road-1 {
                            position: relative;
                            aspect-ratio: 1/1;
                            width: 30%;
                            border-radius: 4px;
                        }

                        .roads-croc-small {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            width: 70%;
                            border-radius: 4px;

                            .roads-croc {
                                width: fit-content;
                                height: 70%;
                                min-width: 100%;
                                max-width: 100%;
                            }

                            .roads-small {
                                left: 0;
                                bottom: 0;
                                display: flex;
                                height: 30%;
                                width: calc(95vw - 8px);
                                max-width: 100%;
                                min-width: 100%;
                                overflow: hidden;

                                .roads-small-item {
                                    height: 100%;
                                    width: calc(100% / 3);
                                }
                            }
                        }
                    }

                    .table-table-image {
                        height: 100%;
                        width: 24%;

                        img {
                            max-width: 100%;
                            aspect-ratio: 1/1;
                        }
                    }
                }

                .table-table-footer {
                    width: 100%;
                    height: 10%;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;

                    .road-map-statistic-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 100%;
                        border-radius: 4px;

                        .statistic-count {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 10px;
                            height: 100%;

                            .lbl {
                                text-align: center;
                                display: flex;
                                justify-content: start;
                                align-items: center;
                                gap: 8px;
                                height: 100%;
                                color: #ddd;
                                font-size: 0.9em;
                                font-weight: 600;

                                .banker {
                                    background-color: red;
                                    font-size: 1rem;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 1.4rem;
                                    aspect-ratio: 1/1;
                                    border-radius: 5px;
                                }

                                .player {
                                    background-color: rgb(55, 136, 255);
                                    font-size: 1rem;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 1.4rem;
                                    aspect-ratio: 1/1;
                                    border-radius: 5px;
                                }

                                .tie {
                                    background-color: rgb(21, 146, 82);
                                    font-size: 1rem;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 1.4rem;
                                    aspect-ratio: 1/1;
                                    border-radius: 5px;
                                }

                                .total {
                                    background-color: #a27848;
                                    font-size: 1rem;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 1.4rem;
                                    aspect-ratio: 1/1;
                                    border-radius: 5px;
                                }
                            }
                        }
                    }

                    .table-table-favorite {
                        display: flex;
                        gap: 15px;
                        justify-content: end;
                        align-items: center;

                        .currency {
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }

    @keyframes magnify {
        0% {
            filter: brightness(1);
        }
        100% {
            filter: brightness(1.3);
        }
    }

    @keyframes magnify-grey {
        0% {
            filter: grayscale(1) brightness(1);
        }
        100% {
            filter: grayscale(1) brightness(1.3);
        }
    }

    .anticon {
        color: #bfbfbf;
    }

    .active {
        .ant-card {
            border: 1px solid goldenrod;
            filter: brightness(1.5);
        }

        .anticon {
            color: goldenrod;
        }
    }

    .ant-card {
        border: 1px solid #c2b557;
        background: #000000;
        color: white;
        font-size: 0.65rem;

        .ant-card-body {
            padding: 5px 10px;
        }
    }

    .status-0,
    .status-1,
    .status-2 {
        .ant-card-body {
            padding: 0;

            .ant-card-meta {
                padding: 10px;
            }
        }

        .ant-typography {
            color: #8c749c;
            margin-bottom: 0;
        }

        .ant-btn {
            background: linear-gradient(to bottom, #403348, #100d19);
            border: 1px solid #bfbfbf;
            color: #8c749c;
        }
    }

    .status-0,
    .status-2 {
        .ant-card {
            filter: grayscale(1);
            background: #372a55;
            border: 0;

            &:hover {
                box-shadow: 2px 2px 10px 0px rgb(51, 51, 51);
                animation: magnify-grey 0.2s ease-in-out none;
                animation-fill-mode: forwards;
                z-index: 10;
            }
        }
    }

    .status-1 {
        .ant-card {
            background: #372a55;
            border: 1px solid transparent;

            &:hover {
                box-shadow: 2px 2px 10px 0px rgb(51, 51, 51);
                animation: magnify 0.2s ease-in-out none;
                animation-fill-mode: forwards;
                z-index: 10;
            }
        }
    }
}

@media only screen and (orientation: portrait) {
    @media only screen and (min-width: 200px) {
        .table-list-cont {
            .table-list-data-container {
                .table-table-wrapper {
                    .roulette-table {
                        width: 100%;
                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.7rem;
                            }
                        }
                    }
                    .table-table {
                        width: 100%;
                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.7rem;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 400px) {
        .table-list-cont {
            .table-list-data-container {
                .table-table-wrapper {
                    .roulette-table {
                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.8rem;
                            }
                        }
                    }
                    .table-table {
                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.8rem;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .table-list-cont {
            .table-list-data-container {
                .table-table-wrapper {
                    .roulette-table {
                        width: calc(100% / 2.1);
                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.9rem;
                            }
                        }
                    }

                    .table-table {
                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.9rem;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1024px) {
        .table-list-cont {
            .table-list-data-container {
                .table-table-wrapper {
                    .roulette-table {
                        width: calc(100% / 2.1);
                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.8rem;
                            }
                        }
                    }
                    .table-table {
                        width: calc(100% / 2.1);
                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.8rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (orientation: landscape) {
    @media only screen and (min-width: 668px) {
        .table-list-cont {
            .table-list-data-container {
                .table-table-wrapper {
                    .roulette-table {
                        width: calc(100% / 2.1);
                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.7rem;
                            }
                        }
                    }
                    .table-table {
                        width: calc(100% / 2.1);

                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.7rem;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 900px) {
        .table-list-cont {
            .table-list-data-container {
                .table-table-wrapper {
                    .roulette-table {
                        width: calc(100% / 2.1);
                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.8rem;
                            }
                        }
                    }
                    .table-table {
                        width: calc(100% / 2.1);
                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.8rem;
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1100px) {
        .table-list-cont {
            .table-list-data-container {
                .table-table-wrapper {
                    .roulette-table {
                        width: calc(100% / 2.1);
                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.9rem;
                            }
                        }
                    }
                    .table-table {
                        .table-table-header {
                            .table-table-currency {
                                font-size: 0.9rem;
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1400px) {
        .table-list-cont {
            .table-list-data-container {
                .table-table-wrapper {
                    .roulette-table {
                        width: calc(100% / 3.1);
                        .table-table-header {
                            .table-table-currency {
                                font-size: 1rem;
                            }
                        }
                    }
                    .table-table {
                        width: calc(100% / 3.1);

                        .table-table-header {
                            .table-table-currency {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
