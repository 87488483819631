.v-roulette-bet-panel-container {
    height: 100%;
    width: 100%;
    color: #ddd;
    text-overflow: ellipsis;
    white-space: nowrap;

    .normal-bet-panel-container {
        display: flex;
        width: 100%;
        height: 100%;

        .normal-bet-panel {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            cursor: pointer;

            .sub-bet-item {
                height: 100%;
                width: 25%;
                display: grid;
                grid-template-columns: repeat(2, calc(100% / 2));
                grid-template-rows: repeat(14, calc(100% / 14));

                .sub-bet-item-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgb(33, 33, 36, 0.9);
                    box-shadow: 0px 0px 0px 1px #efc3b0 inset;
                    color: #efc3b0;
                    cursor: pointer;
                    position: relative;
                    text-wrap: wrap;
                    text-align: center;

                    .chip-placed {
                        position: absolute;
                        aspect-ratio: 1/1;
                    }

                    .diamond {
                        width: 50%;
                        aspect-ratio: 1/1;
                        transform: rotate(45deg);
                        box-shadow: 0px 0px 0px 1px #efc3b0 inset;

                        &.red {
                            background-color: red;
                        }

                        &.black {
                            background-color: black;
                        }
                    }
                }

                .sub-bet-item-0,
                .sub-bet-item-10 {
                    grid-column: span 2;
                    grid-row: span 1;
                    aspect-ratio: auto;
                }

                .sub-bet-item-1,
                .sub-bet-item-3,
                .sub-bet-item-4,
                .sub-bet-item-6,
                .sub-bet-item-7,
                .sub-bet-item-9 {
                    grid-column: span 1;
                    grid-row: span 2;
                    aspect-ratio: auto;
                }

                .sub-bet-item-2,
                .sub-bet-item-5,
                .sub-bet-item-8 {
                    grid-column: span 1;
                    grid-row: span 4;
                    aspect-ratio: auto;
                }
            }

            .main-bet-item {
                height: 100%;
                width: 50%;
                display: grid;
                grid-template-columns: repeat(3, calc(100% / 3));
                grid-template-rows: repeat(14, calc(100% / 14));

                .main-bet-item-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #efc3b0;
                    cursor: pointer;
                    position: relative;
                    text-wrap: wrap;
                    text-align: center;

                    .main-bet-item-child {
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .chip-placed {
                            position: absolute;
                            aspect-ratio: 1/1;
                        }

                        &.main-item-0 {
                            height: 100%;
                            width: 100%;
                            box-shadow: 0px 0px 0px 1px #efc3b0 inset;
                            background-color: rgb(33, 33, 36, 0.9);
                        }

                        &.main-item-1 {
                            top: 0;
                            right: 0;
                            transform: translate(50%, -50%);
                            height: calc(100% / 3);
                            aspect-ratio: 1/1;
                            z-index: 2;
                            // background: blue;
                        }

                        &.main-item-2 {
                            top: 50%;
                            right: 0;
                            transform: translate(50%, -50%);
                            height: calc(100% / 1.5);
                            aspect-ratio: 1/2;
                            z-index: 2;
                            // background: yellow;
                        }

                        &.main-item-3 {
                            top: 0;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: calc(100% / 1.3);
                            aspect-ratio: 4/1;
                            z-index: 2;
                            // background: green;
                        }

                        &.main-item-4 {
                            top: 0;
                            left: 0;
                            transform: translate(-50%, -50%);
                            height: calc(100% / 3);
                            aspect-ratio: 1/1;
                            z-index: 2;
                            // background: white;
                        }

                        &.main-item-5 {
                            bottom: 50%;
                            left: 0;
                            transform: translate(-50%, 50%);
                            height: calc(100% / 1.5);
                            aspect-ratio: 1/2;
                            z-index: 2;
                            // background: aqua;
                        }

                        &.green {
                            background-color: rgb(22, 105, 88, 0.9);
                            border-top-left-radius: 200px;
                            border-top-right-radius: 200px;
                        }

                        &.red {
                            background-color: rgb(181, 28, 18, 0.9);
                        }

                        &.black {
                            background-color: rgb(33, 33, 36, 0.9);
                        }

                        &.undefined {
                            font-size: 1em !important;
                        }
                    }
                }

                .main-bet-item-0 {
                    grid-column: span 3;
                    grid-row: span 1;
                    aspect-ratio: auto;
                }
            }
        }
    }

    .h-roulette-bet-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        flex-direction: column;
        cursor: pointer;

        .main-bet-panel-container {
            height: calc(100% / 5 * 2);
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            text-align: center;
            flex-wrap: wrap;

            .main-bet-panel-item {
                height: calc(100% / 3);
                width: calc(100% / 14);
                position: relative;
                color: #efc3b0;
                font-size: 1rem;

                &.main-panel-item-0 {
                    height: 100%;
                    border-top-left-radius: 200px;
                    border-bottom-left-radius: 200px;
                }

                &.main-panel-item-37,
                &.main-panel-item-38,
                &.main-panel-item-39 {
                    background-color: rgb(33, 33, 36, 0.8);
                    font-size: 0.8rem;
                }
                .main-bet-item {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .chip-placed {
                        position: absolute;
                        aspect-ratio: 1/1;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &.win {
                        animation: light 1.5s infinite ease-in-out;
                        @keyframes light {
                            0% {
                                filter: brightness(1);
                            }
                            50% {
                                filter: brightness(1.5);
                            }
                            100% {
                                filter: brightness(1);
                            }
                        }
                    }

                    &.bet-hover {
                        border: 1px solid #efc3b0;
                        filter: brightness(1.5);
                    }

                    &.main-item-0 {
                        height: 100%;
                        width: 100%;
                        box-shadow: 0px 0px 0px 1px #efc3b0 inset;

                        &.green {
                            background-color: rgb(22, 105, 88, 0.9);
                            border-top-left-radius: 200px;
                            border-bottom-left-radius: 200px;
                        }

                        &.red {
                            background-color: rgb(181, 28, 18, 0.9);
                        }

                        &.black {
                            background-color: rgb(33, 33, 36, 0.9);
                        }

                        &.undefined {
                            font-size: 1em !important;
                        }
                    }

                    &.main-item-1 {
                        top: 0;
                        left: 0;
                        transform: translate(-50%, -50%);
                        height: calc(100% / 3);
                        aspect-ratio: 1/1;
                        z-index: 2;
                        // background: blue;
                    }

                    &.main-item-2 {
                        top: 0;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        height: calc(100% / 4);
                        aspect-ratio: 4.5/1;
                        z-index: 2;
                        // background: yellow;
                    }

                    &.main-item-3 {
                        top: 50%;
                        left: 0;
                        transform: translate(-50%, -50%);
                        width: calc(100% / 5);
                        aspect-ratio: 1/2.5;
                        z-index: 2;
                        // background: green;
                    }

                    &.main-item-4 {
                        bottom: 0;
                        left: 0;
                        transform: translate(-50%, 50%);
                        height: calc(100% / 3);
                        aspect-ratio: 1/1;
                        z-index: 2;
                        // background: white;
                    }

                    &.main-item-5 {
                        bottom: 0;
                        left: 50%;
                        transform: translate(-50%, 50%);
                        height: calc(100% / 4);
                        aspect-ratio: 4.5/1;
                        z-index: 2;
                        // background: aqua;
                    }
                }
            }
        }

        .sub-bet-panel-container {
            height: calc(100% / 5 * 1.2);
            width: calc(100% / 14 * 12);
            display: flex;
            flex-wrap: wrap;

            .sub-bet-panel-item {
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 100%;
                height: calc(100% / 2);
                background-color: rgb(33, 33, 36, 0.8);
                box-shadow: 0px 0px 0px 1px #efc3b0 inset;
                color: #efc3b0;
                position: relative;

                .chip-placed {
                    position: absolute;
                    aspect-ratio: 1/1;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &.win {
                    animation: light 1.5s infinite ease-in-out;
                    @keyframes light {
                        0% {
                            filter: brightness(1);
                        }
                        50% {
                            filter: brightness(1.5);
                        }
                        100% {
                            filter: brightness(1);
                        }
                    }
                }

                &.sub-panel-item-0,
                &.sub-panel-item-1,
                &.sub-panel-item-2 {
                    width: calc(100% / 3);
                    min-width: calc(100% / 3);
                }

                &.sub-panel-item-3,
                &.sub-panel-item-4,
                &.sub-panel-item-5,
                &.sub-panel-item-6,
                &.sub-panel-item-7,
                &.sub-panel-item-8 {
                    width: calc(100% / 6);
                    min-width: calc(100% / 6);
                }

                .diamond {
                    height: 50%;
                    aspect-ratio: 1/1;
                    transform: rotate(45deg);
                    box-shadow: 0px 0px 0px 1px #efc3b0 inset;

                    &.red {
                        background-color: rgb(255, 0, 0, 0.7);
                    }

                    &.black {
                        background-color: rgb(33, 33, 36, 0.9);
                    }
                }
            }
        }
    }

    .h-roulette-oval-bet-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .roulette-oval-main-bet-panel-container {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(18, calc(100% / 18));
            grid-template-rows: repeat(4, calc(100% / 4));

            .main-bet-item {
                width: 100%;
                aspect-ratio: 1/1;
                box-shadow: 0px 0px 0px 1px #efc3b0 inset;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #efc3b0;
                cursor: pointer;
                // font-size: 0.8rem;
                position: relative;
                text-wrap: wrap;
                text-align: center;

                .chip-placed {
                    position: absolute;
                    aspect-ratio: 1/1;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &.bet-hover {
                    border: 1px solid #efc3b0;
                    filter: brightness(1.5);
                }

                &.red {
                    background-color: rgb(181, 28, 18, 0.9);
                }

                &.green {
                    background-color: rgb(22, 105, 88, 0.9);
                }

                &.black {
                    background-color: rgb(33, 33, 36, 0.9);
                }

                &.transparent {
                    background-color: rgb(33, 33, 36, 0.9);
                }

                &:nth-child(1) {
                    border-top-left-radius: 20px;
                }

                &:nth-child(18) {
                    border-top-right-radius: 20px;
                }

                &:nth-child(20) {
                    grid-column: span 4;
                    grid-row: span 2;
                    aspect-ratio: auto;
                }

                &:nth-child(21),
                &:nth-child(22) {
                    grid-column: span 5;
                    grid-row: span 2;
                    aspect-ratio: auto;
                }

                &:nth-child(23) {
                    grid-column: span 2;
                    grid-row: span 2;
                    aspect-ratio: auto;
                }

                &:nth-child(26) {
                    grid-row: span 2;
                    aspect-ratio: auto;
                    border-bottom-right-radius: 20px;
                }

                &:nth-child(27) {
                    border-bottom-left-radius: 20px;
                }

                &:nth-child(32),
                &:nth-child(34) {
                    grid-column: span 2;
                    aspect-ratio: auto;
                }

                .sub-bet-item-container {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    .sub-bet-item {
                        height: 100%;
                        width: 100%;
                        position: absolute;

                        &.left {
                            left: 0;
                        }

                        &.right {
                            right: 0;
                        }
                    }
                }
            }
        }
    }

    .oval-bet-panel-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .oval-bet-panel {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 50%;
            cursor: pointer;

            .oval-bet-item-wrapper {
                height: 100%;
                width: 100%;
                display: grid;
                grid-template-columns: repeat(4, calc(100% / 4));
                grid-template-rows: repeat(18, calc(100% / 18));

                .oval-bet-item {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0px 0px 0px 1px #efc3b0 inset;

                    .chip-placed {
                        position: absolute;
                        aspect-ratio: 1/1;
                    }

                    &.oval-bet-item-1 {
                        border-top-left-radius: 20px;
                    }

                    &.oval-bet-item-4 {
                        border-top-right-radius: 20px;
                    }

                    &.oval-bet-item-6 {
                        grid-column: span 2;
                        grid-row: span 4;
                    }

                    &.oval-bet-item-14,
                    &.oval-bet-item-20 {
                        grid-column: span 1;
                        grid-row: span 2;
                    }

                    &.oval-bet-item-15,
                    &.oval-bet-item-24 {
                        grid-column: span 2;
                        grid-row: span 5;
                    }

                    &.oval-bet-item-35 {
                        grid-column: span 2;
                        grid-row: span 2;
                    }

                    &.oval-bet-item-39 {
                        grid-column: span 2;
                        grid-row: span 1;
                        border-bottom-left-radius: 20px;
                    }

                    &.oval-bet-item-41 {
                        border-bottom-right-radius: 20px;
                    }

                    &.green {
                        background-color: rgb(22, 105, 88, 0.9);
                    }

                    &.red {
                        background-color: rgb(181, 28, 18, 0.9);
                    }

                    &.black {
                        background-color: rgb(33, 33, 36, 0.9);
                    }

                    &.undefined {
                        font-size: 1em !important;
                    }

                    &.transparent {
                        text-wrap: wrap;
                        text-align: center;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1px) {
        .normal-bet-panel-container {
            .normal-bet-panel {
                .sub-bet-item {
                    .sub-bet-item-wrapper {
                        .chip-placed {
                            height: 2rem;
                            font-size: 1rem;
                        }
                    }
                }
                .main-bet-item {
                    .main-bet-item-wrapper {
                        .main-bet-item-child {
                            .chip-placed {
                                height: 1.5rem;
                                font-size: 0.8rem;
                            }
                        }
                    }
                }
            }
        }
        .oval-bet-panel-container {
            .oval-bet-panel {
                .oval-bet-item-wrapper {
                    .oval-bet-item {
                        .chip-placed {
                            height: 1.4rem;
                            font-size: 0.7rem;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 400px) {
        .normal-bet-panel-container {
            .normal-bet-panel {
                .sub-bet-item {
                    .sub-bet-item-wrapper {
                        .chip-placed {
                            height: 2.5rem;
                            font-size: 1.2rem;
                        }
                    }
                }
                .main-bet-item {
                    .main-bet-item-wrapper {
                        .main-bet-item-child {
                            .chip-placed {
                                height: 2rem;
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
        .oval-bet-panel-container {
            .oval-bet-panel {
                .oval-bet-item-wrapper {
                    .oval-bet-item {
                        .chip-placed {
                            height: 1.8rem;
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 700px) {
        .h-roulette-bet-panel {
            .main-bet-panel-container {
                .main-bet-panel-item {
                    .main-bet-item {
                        font-size: 1.7em;
                        font-weight: 800;

                        &.main-item-0 {
                            &.undefined {
                                font-size: 1.2em !important;
                            }
                        }

                        .chip-placed {
                            height: 2.8rem;
                            font-size: 1.2rem;
                        }
                    }
                }
            }
            .sub-bet-panel-container {
                .sub-bet-panel-item {
                    font-size: 1.5em;
                    font-weight: 800;

                    .chip-placed {
                        height: 2.8rem;
                        font-size: 1.2rem;
                    }
                }
            }
        }
        .h-roulette-oval-bet-panel {
            .roulette-oval-main-bet-panel-container {
                .main-bet-item {
                    font-size: 1.7em;
                    font-weight: 800;
                    .chip-placed {
                        height: 2rem;
                        font-size: 0.8rem;
                    }

                    &.transparent {
                        .chip-placed {
                            height: 3rem;
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1000px) {
        // width: 60%;
        .h-roulette-bet-panel {
            .main-bet-panel-container {
                .main-bet-panel-item {
                    .main-bet-item {
                        font-size: 2em;
                        &.main-item-0 {
                            &.undefined {
                                font-size: 1.8em !important;
                            }
                        }
                        .chip-placed {
                            height: 4rem;
                            font-size: 1.8rem;
                        }
                        &.transparent {
                            .chip-placed {
                                height: 4rem;
                                font-size: 1.8rem;
                            }
                        }
                    }
                }
            }
            .sub-bet-panel-container {
                .sub-bet-panel-item {
                    font-size: 1.8rem;
                    .chip-placed {
                        height: 4rem;
                        font-size: 1.8rem;
                    }
                }
            }
        }
        .h-roulette-oval-bet-panel {
            .roulette-oval-main-bet-panel-container {
                .main-bet-item {
                    font-size: 2em;
                    .chip-placed {
                        height: 3rem;
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}
