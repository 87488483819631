.rt-how-to-play {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .section {
        .title {
            font-size: 1.5em;
            font-weight: 700;
            color: gold;
        }

        .content {
            font-size: 0.9em;

            .content-title {
                font-size: 1em;
                font-weight: bold;
                color: aquamarine;
            }

            ul {
                padding-left: 15px;
                // list-style-type: decimal;

                li:not(:first-child) {
                    margin-top: 5px;
                }
            }

            div {
                margin-bottom: 10px;
            }

            table {
                margin-bottom: 10px;
                width: 100%;
                border-radius: 4px;
                border-collapse: collapse;
                overflow: hidden;

                :is(th, td) {
                    padding: 5px 0;
                    border: 1px solid #ddd;
                }

                td:first-child {
                    padding-left: 5px;
                }

                td:not(:first-child) {
                    text-align: center;
                }
            }
        }
    }
}
