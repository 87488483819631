.tbl-list-drawer {
    .ant-drawer-content-wrapper {
        .ant-drawer-content {
            background-color: rgba(0, 0, 0, 0.7);
            border: 1px solid grey;
            border-radius: 8px;

            .ant-drawer-header {
                padding: 5px 10px;
            }
            .ant-drawer-body {
                padding: 10px 15px;
            }
        }
    }

    .ant-tabs {
        color: white;

        .ant-tabs-nav {
            .ant-tabs-nav-wrap {
                .ant-tabs-nav-list {
                    .ant-tabs-tab-active {
                        background-color: black;

                        .ant-tabs-tab-btn {
                            color: gold;
                        }
                    }
                }
            }
        }
    }

    .tbl-list-item {
        .ant-card {
            background: #372a55;
            border: 1px solid transparent;
            .ant-card-body {
                padding: 0;

                .ant-typography {
                    font-size: 12px;
                }

                .ant-card-meta {
                    padding: 5px 10px;
                }
            }
        }

        .tbl-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .tbl-detail-container {
                .tbl-detail-title {
                    color: gold;
                }
                .tbl-detail-minmax {
                    color: #ddd;
                }
            }
            .tbl-icon {
                color: #ddd;
            }
        }
    }

    .selected {
        .ant-card {
            border: 1px solid gold;
        }
    }
}
